import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Comprehensive currency symbol mapping
const CURRENCY_SYMBOLS = {
  'INR': '₹',
  '₹': '₹',
  'USD': '$',
  '$': '$',
  'EUR': '€',
  '€': '€',
  'GBP': '£',
  '£': '£',
  // Add more currencies as needed
};

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Helvetica',
  },
  header: {
    marginBottom: 20,
    borderBottom: 1,
    paddingBottom: 10,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  orderInfo: {
    marginBottom: 20,
  },
  infoRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  label: {
    width: 120,
    fontWeight: 'bold',
  },
  value: {
    flex: 1,
  },
  productsTable: {
    marginTop: 20,
    marginBottom: 20,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingBottom: 5,
    fontWeight: 'bold',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#999',
    paddingVertical: 5,
  },
  col1: { width: '40%' },
  col2: { width: '20%' },
  col3: { width: '20%' },
  col4: { width: '20%' },
  total: {
    marginTop: 20,
    alignItems: 'flex-end',
  },
  address: {
    marginTop: 20,
    padding: 10,
    backgroundColor: '#f5f5f5',
  },
});

const OrderReceipt = ({ order, currencySymbol }) => {
  // Ensure the currency symbol is ready before rendering
  if (!currencySymbol) return null;

  const formatAmount = (amount) => {
    try {
      return `INR ${amount}`;
    } catch (error) {
      console.error('Error formatting amount:', error);
      return `INR ${amount}`; // fallback
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Order Receipt</Text>
        </View>

        <View style={styles.orderInfo}>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Order ID:</Text>
            <Text style={styles.value}>{order.id}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Order Date:</Text>
            <Text style={styles.value}>
              {new Intl.DateTimeFormat("en-IN", {
                day: "numeric",
                month: "long",
                year: "numeric",
              }).format(order.createdAt.toDate())}
            </Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Payment Method: {currencySymbol} </Text>
            <Text style={styles.value}>{order.paymentMethod.toUpperCase()}</Text>
          </View>
        </View>

        <View style={styles.productsTable}>
          <View style={styles.tableHeader}>
            <Text style={styles.col1}>Product</Text>
            <Text style={styles.col2}>Price</Text>
            <Text style={styles.col3}>Quantity</Text>
            <Text style={styles.col4}>Total</Text>
          </View>
          
          {order.products.map((product, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.col1}>{product.name}</Text>
              <Text style={styles.col2}>{formatAmount(product.price)}</Text>
              <Text style={styles.col3}>{product.quantity}</Text>
              <Text style={styles.col4}>
                {formatAmount(product.price * product.quantity)}
              </Text>
            </View>
          ))}
        </View>

        <View style={styles.total}>
          <Text>Total Amount: {formatAmount(order.totalPrice)}</Text>
        </View>

        <View style={styles.address}>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Shipping Address:</Text>
          <Text>
            {order.shippingAddress.name}{'\n'}
            {order.shippingAddress.houseNumber}{'\n'}
            {order.shippingAddress.streetLineOne}{'\n'}
            {order.shippingAddress.streetLineTwo}{'\n'}
            {order.shippingAddress.landmark}{'\n'}
            {order.shippingAddress.city}, {order.shippingAddress.state}{'\n'}
            {order.shippingAddress.pincode}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default OrderReceipt;
import React, { useEffect, useState } from "react";
import "./OrdersPage.scss";
import { useDocument } from "../firebase files/hooks/useDocument";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import useOrders from "../firebase files/hooks/useOrders";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { pdf } from '@react-pdf/renderer';
import OrderReceipt from './OrderReceipt';
import { useAdminOrders } from "../firebase files/hooks/useAdminOrders";

// Add this helper function at the top of the file, outside the component
const getDateRangeStart = (period) => {
  const now = new Date();
  switch (period) {
    case '1month':
      return new Date(now.setMonth(now.getMonth() - 1));
    case '3months':
      return new Date(now.setMonth(now.getMonth() - 3));
    case '6months':
      return new Date(now.setMonth(now.getMonth() - 6));
    case '1year':
      return new Date(now.setFullYear(now.getFullYear() - 1));
    case '2023':
      return new Date('2023-01-01');
    case '2022':
      return new Date('2022-01-01');
    default:
      return null;
  }
};

const OrdersPage = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const { orders: userOrders } = useOrders();
  const { orders: adminOrders } = useAdminOrders();

  // Use admin orders if user is admin, otherwise use user orders
  const orders = user.role === "admin" ? adminOrders : userOrders;

  const { document: currencyDocument, error: currencyError } = useDocument(
    "settings",
    "currency"
  );

  const {documents:usersDocuments,error:usersError} = useCollection("users");

  console.log(usersDocuments)

  const [isLoading, setIsLoading] = useState(true);

  // State to track which order's product list is open
  const [openOrderId, setOpenOrderId] = useState(null);

  const [currentStage, setCurrentStage] = useState(2);

  // Add new state for order status
  const [orderStatus, setOrderStatus] = useState('all');

  // Replace orderDate state with timePeriod
  const [timePeriod, setTimePeriod] = useState('all');

  // Function to toggle the product list visibility
  const toggleProductList = (orderId) => {
    setOpenOrderId(openOrderId === orderId ? null : orderId);
  };

  console.log(orders);

  const [ordersData,setOrdersData] = useState(orders)

  console.log(ordersData)

  console.log("Date",timePeriod);

  useEffect(() => {
    if (currencyDocument && orders && usersDocuments) {
      setOrdersData(orders)
      setIsLoading(false);
    }
  }, [currencyDocument,orders,usersDocuments]);

  useEffect(() => {
    if (orders) {
      let filteredOrders = [...orders];
      
      // Filter by time period if not 'all'
      if (timePeriod !== 'all') {
        const startDate = getDateRangeStart(timePeriod);
        const endDate = timePeriod.includes('202') 
          ? new Date(parseInt(timePeriod) + 1, 0, 1) // First day of next year
          : new Date(); // Current date

        filteredOrders = filteredOrders.filter((order) => {
          const orderDate = order.createdAt.toDate();
          return orderDate >= startDate && orderDate < endDate;
        });
      }
      
      // Only apply status filter if not 'all'
      if (orderStatus !== 'all') {
        filteredOrders = filteredOrders.filter((order) => 
          order.orderStatus === orderStatus
        );
      }
      
      setOrdersData(filteredOrders);
    }
  }, [timePeriod, orders, orderStatus]);
  


  

  // Add this function to handle receipt generation and opening in new tab
  const handleReceiptClick = async (order) => {
    try {
      // Generate the PDF blob
      const blob = await pdf(
        <OrderReceipt 
          order={order} 
          currencySymbol={currencyDocument.symbol}
        />
      ).toBlob();
      
      // Create a URL for the blob
      const url = URL.createObjectURL(blob);
      
      // Open in new tab
      window.open(url, '_blank');
      
      // Clean up the URL after opening
      setTimeout(() => URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  // Helper function to get user details
  const getUserDetails = (userId) => {
    if (!usersDocuments) return { fullName: "N/A", phoneNumber: "N/A" };
    
    const user = usersDocuments.find(user => user.id === userId);
    return user 
      ? { fullName: user.fullName, phoneNumber: user.phoneNumber }
      : { fullName: "N/A", phoneNumber: "N/A" };
  };

  if (isLoading) {
    return (
      <div style={{ height: "100vh", padding: "10px" }}>
        <Skeleton count={5} height={100} className="mb-4" />
      </div>
    );
  }

  return (
    <>
    {
      !user 
      ?
      <div style={{height:"50vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
         <button style={{backgroundColor:"var(--click-color)",color:"white",borderRadius:"20px",padding:"10px 20px",border:"none",outline:"none"}}onClick={()=>navigate("/login")}>Please Login</button>
      </div>
      :
      <div className="orders-page">
      <h1>Orders</h1>

      <div className="orders-utilities">
        <div className="orders-utilities-item">
          <p>Filter by status</p>

          <div className="order-item-status-tabs">
            <span 
              className={orderStatus === 'all' ? 'active-order-status-tab' : ''} 
              onClick={() => setOrderStatus('all')}
            >
              All
            </span>
            <span 
              className={orderStatus === 'pending' ? 'active-order-status-tab' : ''} 
              onClick={() => setOrderStatus('pending')}
            >
              Pending
            </span>
            <span 
              className={orderStatus === 'completed' ? 'active-order-status-tab' : ''} 
              onClick={() => setOrderStatus('completed')}
            >
              Completed
            </span>
            <span 
              className={orderStatus === 'cancelled' ? 'active-order-status-tab' : ''} 
              onClick={() => setOrderStatus('cancelled')}
            >
              Cancelled
            </span>
          </div>
        </div>

        <div className="orders-utilities-filter">
          <div className="orders-utilities-filter-timeline">
            <p>Filter by timeline</p>
            <select 
              value={timePeriod} 
              onChange={(e) => setTimePeriod(e.target.value)}
              className="timeline-dropdown"
            >
              <option value="all">All Time</option>
              <option value="1month">Last Month</option>
              <option value="3months">Last 3 Months</option>
              <option value="6months">Last 6 Months</option>
              {/* <option value="1year">Last Year</option> */}
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </select>
          </div>
        </div>
      </div>

      <div className="orders-list">
        <h3>{ordersData.length} {ordersData.length === 1 ? "Order" : "Orders"} found</h3>
        {
          ordersData.length > 0
            ? ordersData.map((order) => {
                // Get user details for this order
                const userDetails = getUserDetails(order.createdBy);

                return (
                  <div className="order-item" key={order.id}>
                    <div className="order-item-header">
                      <div className="order-header-left">
                        <h5># {order.id}</h5>
                        <div className="order-user-info">
                          <span className="user-name">
                            <i className="material-symbols-outlined">person</i>
                            {userDetails.fullName}
                          </span>
                          <span className="user-phone">
                            <i className="material-symbols-outlined">phone</i>
                            {userDetails.phoneNumber}
                          </span>
                        </div>
                      </div>

                      <div className="order-item-header-info">
                        <div>
                          <span>Order Placed On</span>
                          <span>
                            {new Intl.DateTimeFormat("en-IN", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }).format(order.createdAt.toDate())}
                          </span>
                        </div>

                        <div>
                          <span>Order Status</span>
                          <span style={{textTransform:"capitalize"}}>{order.orderStatus}</span>
                        </div>

                        <div>
                          <span>Order Value</span>
                          <span>
                            {currencyDocument.symbol} {order.totalPrice}
                          </span>
                        </div>

                        <div>
                          <span>Payment Method</span>
                          <span  style={{textTransform:"uppercase"}}>{order.paymentMethod}</span>
                        </div>
                      </div>
                    </div>

                    <div className="order-item-body">
                      <div className="order-process-status">
                        <div
                          className="status-tracker"
                          style={{ "--current-stage": currentStage }}
                        >
                          <div className="status-bar"></div>
                          {[
                            "Order Placed",
                            "Items Picked Up",
                            "Items Processed",
                            "Delivered",
                          ].map((stage, index) => (
                            <div
                              key={index}
                              className={`status-stage ${
                                index < currentStage - 1 ? "completed" : ""
                              } ${index === currentStage - 1 ? "current" : ""}`}
                            >
                              <div className="status-dot"></div>
                              <span>{stage}</span>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="products-accordion">
                        <div
                          className="products-accordion-header"
                          onClick={() => toggleProductList(order.id)}
                        >
                          <h4>Order Products - {`( ${order.products.length} )`}</h4>
                          <span
                            className={`toggle-icon ${
                              openOrderId === order.id ? "open" : ""
                            }`}
                          >
                            ▼
                          </span>
                        </div>
                        <div
                          className={`products-accordion-content ${
                            openOrderId === order.id ? "open" : ""
                          }`}
                        >
                          <div className="order-item-products">
                            {order.products.map((product, index) => (
                              <div className="order-item-product" key={index}>
                                <img src={product.images[0]} alt={product.name} />
                                <div className="order-item-product-info">
                                  <h3>{product.name}</h3>
                                  <h3>
                                    Price: {currencyDocument.symbol} {product.price}
                                  </h3>
                                  <h3>Quantity: {product.quantity}</h3>
                                  <h3>
                                    Total: {currencyDocument.symbol}{" "}
                                    {product.price * product.quantity}
                                  </h3>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="order-item-footer">
                      <div className="order-item-footer-shipping-address">
                        <span>Shipped To:</span>
                        <p>
                          {order.shippingAddress.name},{" "}
                          {order.shippingAddress.houseNumber},{" "}
                          {order.shippingAddress.streetLineOne},{" "}
                          {order.shippingAddress.streetLineTwo},{" "}
                          {order.shippingAddress.landmark},{" "}
                          {order.shippingAddress.city}, {order.shippingAddress.state},{" "}
                          {order.shippingAddress.pincode}
                        </p>
                      </div>

                      <div className="order-item-footer-buttons">
                        <button 
                          className="order-item-footer-button"
                          onClick={() => handleReceiptClick(order)}
                        >
                          <span className="material-symbols-outlined">receipt</span>
                          <span>Receipt</span>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            : <h1>No Orders Found</h1>
        }
      </div>
    </div>
    }
    </>
    
    
  );
};

export default OrdersPage;

import React, { useState } from "react";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import { useCollection } from "../../firebase files/hooks/useCollection";
import { useNavigate } from "react-router-dom";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import AddNotification from "../../allRoles/admin/addNotification/AddNotification";
import { projectStorage } from "../../firebase config/config";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import Popup from "../../default components/Popup";
import ReactSwitch from "react-switch";

import "./Notification.css";

const Notification = () => {
  const { user } = useAuthContext();
  const [expandedIds, setExpandedIds] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowMoreClick = (notificationId) => {
    setExpandedIds((prevIds) =>
      prevIds.includes(notificationId)
        ? prevIds.filter((id) => id !== notificationId)
        : [...prevIds, notificationId]
    );
  };

  const isExpanded = (notificationId) => expandedIds.includes(notificationId);

  const { documents: dbnotifications, error: dbnotificationserror } = useCollection("notifications");

  // Update document and delete document functionality
  const { updateDocument, deleteDocument } = useFirestore("notifications");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    const updatedDocument = await updateDocument(productId, { status: newStatus });
    if (updatedDocument && updatedDocument.success) {
      console.log("Offer status updated successfully");
    } else {
      console.error("Error updating offer status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    setIsDeletePopupOpen(true);
    setProductIdToDelete(productId);
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      console.log("Deleting product ID:", productIdToDelete);
      const documentToDelete = dbnotifications.find(
        (doc) => doc.id === productIdToDelete
      );

      if (documentToDelete) {
        await deleteDocument(productIdToDelete);
        console.log("Notification deleted successfully");
      } else {
        console.warn("Invalid document or not an image type.");
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  // Filter notifications based on user role
  const filteredNotifications = dbnotifications
    ? dbnotifications.filter((notification) => {
      if (user&&user.role === "admin") return true; // Admin sees all notifications
      if (notification.visibleFor === "all") return true; // Everyone sees notifications visible to all
      return notification.visibleFor === user.role; // Filter by user role
    })
    : [];


  return (
    <div className="container">
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this notification?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      <br/>
      <div className="row notification_div_parent">
        {showModal && (
          <AddNotification onClose={() => setShowModal(false)} />
        )}
        <div className="d-flex justify-content-between">
          <div className="pg_header">
            <h2 className="p_title">Alert Center!</h2>
            <h4 className="p_subtitle">Your Notifications, Stay Updated</h4>
          </div>
          {user && user.role === "admin" &&(
            <div>
              <button className="btn_fill" onClick={() => setShowModal(true)}>Add Notification</button>
            </div>
          )}
          
        </div>
      <div>
      <br/>
      </div>
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <div className="col-12 col-md-6 col-lg-4" key={notification.id}>
              <div
                className={`propagentusersingle ${isExpanded(notification.id) ? "showmore" : ""
                  }`}
              >
                <div className="left">
                  <div>
                    <div className="img_div">
                      <img src="./assets/img/loudspeaker.jpg" alt="" />
                    </div>
                  </div>
                  <div className="">
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          notification.heading.toString("html"),
                      }}
                    ></p>
                    <h6
                      className={`phone_number ${isExpanded(notification.id) ? "expanded" : ""
                        }`}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            notification.fullDetail.toString("html"),
                        }}
                      ></p>
                    </h6>
                  </div>
                </div>
                <h4 className="notification_date">
                  <span>
                    {formatDistanceToNow(notification.createdAt.toDate(), {
                      addSuffix: true,
                    })}
                  </span>
                </h4>
                {notification.fullDetail.length > 110 && (
                  <h6
                    onClick={() => handleShowMoreClick(notification.id)}
                    className="expand_line pointer"
                  >
                    {isExpanded(notification.id) ? "see less" : "see more"}
                  </h6>
                )}
              </div>
              {user&&user.role === "admin" && (
                <div style={{ margin: "0px 0px 20px 15px" }}>
                  <MDBRow>
                    <MDBCol className="col-9">
                      <MDBCardText className="text-muted">
                        <div className="d-flex align-items-center mt-2">
                          <div>
                            <p
                              className="active-inactive-text"
                              style={{ color: "red" }}
                            >
                              Inactive
                            </p>
                          </div>
                          <ReactSwitch
                            checked={notification.status === "active"}
                            onChange={() =>
                              handleToggleChange(notification.id, notification.status)
                            }
                            onColor="#149AA2"
                            offColor="#F05B83"
                            className="products-status-switch"
                          />
                          <div>
                            <p
                              className="active-inactive-text"
                              style={{ color: "red" }}
                            >
                              Active
                            </p>
                          </div>
                        </div>
                      </MDBCardText>
                    </MDBCol>
                    <MDBCol className="col-3 d-flex justify-content-center mt-2">
                      <MDBCardText onClick={() => handleDeleteProduct(notification.id)}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "23px" }}
                        >
                          delete
                        </span>
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No notifications to display</p>
        )}
      </div>
    </div>
  );
};

export default Notification;

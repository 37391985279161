import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PartnerSlider from "../../default components/PartnerSlider";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import RichTextEditor from "react-rte";


const ReturnPolicy = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();
  const { updateDocument, response } = useFirestore("pages-data");
  const { document: pagesTextDataDocument, error: pagesTextDataError } =
    useDocument("pages-data", "privacyPolicy");

  // State to hold the editor value
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [hasChanges, setHasChanges] = useState(false);

  // Load the data into the editor when the document is fetched
  useEffect(() => {
    if (pagesTextDataDocument) {
      setValue(
        RichTextEditor.createValueFromString(
          pagesTextDataDocument.policyBody,
          "html"
        )
      );
    }
  }, [pagesTextDataDocument]);

  // Function to handle save click
  const handleSaveClick = async () => {
    try {
      const content = value.toString("html");

      // Update the document in Firestore
      await updateDocument("privacyPolicy", { policyBody: content });

      if (response.error) {
        console.error("Error updating document:", response.error);
      } else {
        console.log("Document updated successfully");
        setHasChanges(false); // Reset changes tracking after successful save
      }
    } catch (error) {
      console.error("An error occurred while updating the document:", error);
    }
  };

  // Function to handle changes in the editor
  const handleEditorChange = (newValue) => {
    setValue(newValue);
    // Compare the current editor value with the original document to detect changes
    if (newValue.toString("html") !== pagesTextDataDocument.policyBody) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };

  return (
    <div>
      <div className="top_header_pg pa_bg">
        <div className="pa_inner_page info_in_ul_li max-991">
          <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
            <h2>Return Policy</h2>
            <p>Refunds, Returns, Exchanges, and Cancellations</p>
          </div>

          <div className="corporateTwo-policy-body">
            {user && user.role == "admin" && (
            <div>
              <RichTextEditor value={value} onChange={handleEditorChange} />
              {hasChanges && (
                <button
                  className="product_edit_save_btn"
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              )}
            </div>
          )}
          <p
            dangerouslySetInnerHTML={{
              __html:
                pagesTextDataDocument &&
                pagesTextDataDocument.policyBody.toString("html"),
            }}
          ></p>
          </div>
        </div>
      </div>
      {/* Our Partner section */}
      <PartnerSlider
        category="partners"
        pageName="home"
        section="partners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
    </div>
  );
};

export default ReturnPolicy;

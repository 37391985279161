import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { format } from "date-fns";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { projectFirestore } from "../firebase config/config";
import { useDocument } from "../firebase files/hooks/useDocument";

// import scss
import "./UserList.css";

const UserList = ({ user }) => {
  const { document: dbRolesDocuments, error: dbRolesError } =
    useDocument("m_roles", "rolesDoc");

  const firestore = useFirestore("../hooks/useFirestore");

  const { updateDocument } = useFirestore("users");

  let userRoleClass = "";
  if (user.role == "admin") {
    userRoleClass = "admin";
  } else if (user.role == "dealer") {
    userRoleClass = "dealer";
  } else if (user.role == "superAdmin") {
    userRoleClass = "superAdmin";
  } else {
    userRoleClass = "customer";
  }


  const updateUserStatus = (newStatus) => {
    // Update the user's status in Firestore
    projectFirestore
      .collection("users")
      .doc(user.id) // Replace "users" with the actual Firestore collection name
      .update({ status: newStatus })
      .then(() => {
        // Success, you can handle it as needed
        console.log("User status updated successfully");
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error updating user status:", error);
      });
  };

  const updateUserRole = (newRole) => {
    updateDocument(user.id, { role: newRole })
      .then(() => {
        console.log("User role updated successfully");
      })
      .catch((error) => {
        console.error("Error updating user role:", error);
      });
  };
  return (
    <>
      <div
        className={`user_card_single relative ${userRoleClass} ${user.status === "inactive" ? "inactive" : ""
          }`}
      >
        <div className="ucs_body">
          <div className="ucsb_left relative">
            <div className="img_container">
              <img src={user.photoURL} alt="user img 1" />
            </div>
          </div>
          <div className="ucsb_right">
            <div className="ucs_header">
              <div className="ucsh_left">
                <h6>Created at</h6>
                {/* <h5>{ user.createdAt }</h5> */}
                <h5>{format(user.createdAt.toDate(), "MMM dd, yyyy")}</h5>
              </div>
              <div className="ucsh_right">
                <h6>Last login</h6>
                <h5>
                  {format(user.lastLoginTimestamp.toDate(), "MMM dd, yyyy")}
                </h5>
              </div>
            </div>
            <h4 className="u_name">{user.fullName}</h4>
            <h4 className="u_email">{user.email}</h4>
            <h4 className="u_phone">{user.phoneNumber}</h4>
          </div>
        </div>

        <div className="ucs_footer ">
          <h4 className="u_status relative">
            {user.status}
            <Popup
              trigger={
                <div className="pointer edit_bg">
                  <span class="material-symbols-outlined" style={{ color: "black", fontSize: "14px" }}>
                    edit
                  </span>
                </div>
              }
              position="left"
              className="userlist_parent_popup"
            >
              <div className="userlist-popup-parent-div">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "var(--pink)",
                      backgroundColor: "#f2f2f2",
                      width: "100%",
                    }}
                  >
                    Change Status
                  </h5>
                  <br />
                  <button
                    className="btn_fill"
                    style={{
                      backgroundColor: user.status === "active" ? "gray" : "transparent",
                      margin: "0px 5px 5px 5px",
                      color: user.status === "active" ? "white" : "black",
                    }}
                    onClick={() => updateUserStatus("active")}
                  >
                    ACTIVE
                  </button>
                  <button
                    className="btn_fill"
                    style={{
                      margin: "5px",
                      backgroundColor: user.status === "inactive" ? "gray" : "transparent",
                      color: user.status === "inactive" ? "white" : "black",
                    }}
                    onClick={() => updateUserStatus("inactive")}
                  >
                    INACTIVE
                  </button>
                </div>
              </div>
            </Popup>
          </h4>

          <h4 className="relative">
            {user.role}
            <Popup
              trigger={<div className="pointer edit_bg">
                <span className="material-symbols-outlined" style={{ color: "black", fontSize: "14px" }}>edit</span>
                </div>
                }
              position="left"
              className="userlist_parent_popup"
            >
              <div className="userlist-popup-parent-div">
                <h5 style={{ textAlign: "center", fontWeight: "600", color: "var(--pink)", backgroundColor: "#f2f2f2", width: "100%" }}>
                  Change Role
                </h5>
                {dbRolesDocuments?.roles?.map((role) => (
                  <button
                    key={role}
                    className="btn_fill"
                    style={{
                      backgroundColor: role === user.role ? "gray" : "var(--peach-color)",
                      color: role === user.role ? "white" : "black",
                    }}
                    onClick={() => updateUserRole(role)}
                  >
                    {role}
                  </button>
                ))}
              </div>
            </Popup>
          </h4>
        </div>
      </div>
    </>
  );
};

export default UserList;

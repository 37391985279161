import React from 'react'
import './CorporateTwoPGAboutHeader.scss'

const CorporateTwoPGAboutHeader = () => {
  return (
    <div className='corporateTwo-about-pg-header'>
        <h1>About Us</h1>
    </div>
  )
}

export default CorporateTwoPGAboutHeader
import * as React from 'react';
import { useState, useEffect } from 'react';
import "./AccommodationPropertiesList.scss";
import { Link } from 'react-router-dom';

import { DateRange, DefinedRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, format } from 'date-fns'; // Added format for date formatting
import { enUS } from 'date-fns/locale'; // Import the locale from date-fns

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const AccommodationPropertiesList = () => {
  const propertiesList = [
    {
      id: 1,
      imgUrl: "https://wallpaperaccess.com/full/1142283.jpg",
      title: "Rooms in Independent Apartment near Hulimangala Road",
      price: "₹13,499/mo*",
      location: "Electronic City Phase 1",
      features: ["Attached Washroom", "Spacious Cupboard"],
      views: "16 People Viewing Now"
    },
    {
      id: 2,
      imgUrl: "https://rimh2.domainstatic.com.au/_ufKSCodWW-aKukEH3IywBbVqbk=/fit-in/1920x1080/filters:format(jpeg):quality(80):no_upscale()/2017963697_1_1_220728_041112-w3840-h2560",
      title: "Cozy Studio Apartment near Sarjapur Road",
      price: "₹10,999/mo*",
      location: "Sarjapur Road",
      features: ["Attached Washroom", "Furnished"],
      views: "12 People Viewing Now"
    },
    {
      id: 3,
      imgUrl: "https://th.bing.com/th/id/OIP.2t1Ckj7Sz7g-V7Dlz5UARgHaEK?w=1920&h=1080&rs=1&pid=ImgDetMain",
      title: "New Vijay Nagar Near Gwalior Fort",
      price: "₹5,999/mo*",
      location: "Near Gwalior Fort",
      features: ["Attached Washroom", "Furnished", "Parking"],
      views: "6 People Viewing Now"
    }];

  const [searchLocation, setSearchLocation] = useState("Vinay Nagar, Lashkar Gwalior");
  const [showCalendar, setShowCalendar] = useState(false); // State to show/hide the calendar
  const [checkIn, setCheckIn] = useState(format(new Date(), "dd/MM/yyyy")); // Today
  const [checkOut, setCheckOut] = useState(format(addDays(new Date(), 1), "dd/MM/yyyy"));// State for check-out date
  const [showGuestPopup, setShowGuestPopup] = useState(false); // State to show/hide the guest popup
  const [adultCount, setAdultCount] = useState(1); // Set initial adult count to 1
  const [childCount, setChildCount] = useState(0); // State for child count
  const [guestText, setGuestText] = useState(1); // State to store guest text
  const [showRoomPopup, setShowRoomPopup] = useState(false);
  const [roomCount, setRoomCount] = useState(1); 
  const [roomText, setRoomText] = useState(1);
  const [detailOpenPopup, setDetailOpenPopup] = useState(false);
  const [mobileFilterOpenPopup, setMobileFilterOpenPopup] = useState(false);
  const [direction, setDirection] = useState("horizontal");

  // search location code start
  const addLocation = (location) => {
    setSearchLocation(location)
  }

  // Initialize the state with today's date and a default end date
  const today = new Date();
  const [state, setState] = useState([
    {
      startDate: today,
      endDate: addDays(today, 1), // Default end date to one day after today
      key: 'selection'
    }
  ]);

  // Handle date selection
  const handleDateSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
  
    // Update state
    setState([ranges.selection]);
  
    // Update the inputs only when both dates are selected
    if (startDate && endDate) {
      setCheckIn(format(startDate, "dd/MM/yyyy")); // Format as dd/MM/yyyy
      setCheckOut(format(endDate, "dd/MM/yyyy")); // Format as dd/MM/yyyy
  
      // Ensure check-in and check-out dates are different
      if (startDate.getTime() !== endDate.getTime()) {
        // Hide calendar if both dates are different
        setShowCalendar(false);
        setShowGuestPopup(true);
      }
    }
  };

  // Function to handle adult count increment/decrement
  const changeAdultCount = (amount) => {
    setAdultCount(prevCount => Math.max(1, prevCount + amount)); // Prevent negative count, minimum is 1
  };

  // Function to handle child count increment/decrement
  const changeChildCount = (amount) => {
    setChildCount(prevCount => Math.max(0, prevCount + amount)); // Prevent negative count
  };

  // Function to update guest text when "Done" is clicked
  const handleDoneClick = () => {
    let text = `${adultCount} Adult`;
    if (childCount > 0) {
      text += `, ${childCount} Children`;
    }
    setGuestText(text);
    setShowGuestPopup(false);
    setShowRoomPopup(true);
  };

  // Function to handle room count increment/decrement
  const changeRoomCount = (amount) => {
    setRoomCount(prevCount => Math.max(1, prevCount + amount));
  };

  // Function to update guest text when "Done" is clicked
  const handleRoomDoneClick = () => {
    let text = `${roomCount}`;
    setRoomText(text);
    setShowRoomPopup(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const isPC = window.innerWidth >= 992;
      setDetailOpenPopup(isPC);
      setMobileFilterOpenPopup(isPC);
    };

    // Check screen size on initial render
    handleResize();

    // Add event listener to update state on resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const updateDirection = () => {
      setDirection(window.innerWidth < 768 ? "vertical" : "horizontal");
    };

    // Set initial direction
    updateDirection();

    // Update on resize
    window.addEventListener("resize", updateDirection);
    return () => window.removeEventListener("resize", updateDirection);
  }, []);

  {/* PHONE VIEW TOP DETAILS SECTION START */ }
  const openDetailPopup = () => {
    setMobileFilterOpenPopup(false);
    setDetailOpenPopup(!detailOpenPopup);
  }
  {/* PHONE VIEW TOP DETAILS SECTION END */ }

  const openMobileFilterPopup =()=>{
    setDetailOpenPopup(false);
    setMobileFilterOpenPopup(!mobileFilterOpenPopup);
  }
  return (
    <div>
      {/* SELETE CHECKING AND CHECK OUT DATE START */}

      {/* PHONE VIEW TOP DETAILS SECTION START */}
      <div className='accommodation-property-list-phone-view-detail'>
        <div>
          <h6><b>Vijay Nagar, Gwalior</b></h6>
          <div className='d-flex align-items-center'>
            <p style={{ fontSize: "13px", color: "var(--grey-dark)" }}>26 Nov - 28 Nov</p>, &nbsp;
            <p style={{ fontSize: "13px", color: "var(--grey-dark)" }}>2 Adults, 1 Child</p>&nbsp;
            <span class="material-symbols-outlined" onClick={openDetailPopup} style={{ fontSize: "16px" }}>
              edit
            </span>
          </div>
        </div>
        <div>
          <span class="material-symbols-outlined" onClick={openMobileFilterPopup}>
            tune
          </span>
        </div>
      </div>
      {/* PHONE VIEW TOP DETAILS SECTION END */}

      {detailOpenPopup &&
        <div>
          <div className='accommodation-property-list-checked-date'>
            <Box
              component="form"
              className='row justify-content-between'
              noValidate
              autoComplete="off"
            >
              <div className='col-sm-12 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Area, City, Landmark, Property..." variant="outlined" value={searchLocation} onChange={(e) => addLocation(e.target.value)} /></div>
              <div className='col-6 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Check-In" variant="outlined" onFocus={() => setShowCalendar(true)} value={checkIn} readOnly /></div>
              <div className='col-6 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Check-Out" variant="outlined" onFocus={() => setShowCalendar(true)} value={checkOut} readOnly /></div>
              <div className='col-6 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Guests" variant="outlined" value={`${guestText}`} readOnly onClick={() => setShowGuestPopup("true")} /></div>
              <div className='col-6 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Rooms" variant="outlined" value={`${roomText} Room`}  readOnly onClick={() => setShowRoomPopup("true")} /></div>
            </Box>
            
          </div>

          <div className='d-flex accommodation-property-list-both-calender'>
            {showCalendar && (
              <div className="calendar-overlay" onClick={() => setShowCalendar(false)}>
                <div
                  className="accommodation-property-calendar"
                  onClick={(e) => e.stopPropagation()} 
                  style={{
                    maxHeight: "400px", // Adjust based on your needs
                    overflowY: direction === "vertical" ? "scroll" : "visible",
                    overflowX: "hidden",
                  }}
                >
                  <DateRange
                    onChange={handleDateSelect}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction={direction}
                    locale={enUS}
                    minDate={today} // Disable past dates
                  />
                </div>
              </div>
            )}

            {showGuestPopup && (
              <div className="guest-popup">
                <div className="guest-popup-content">
                  <div className="guest-counter">
                    <div>
                      <span>Adults :</span><br />
                      <span style={{ fontWeight: "100", fontSize: "12px" }}>Above 18 Years</span>
                    </div>
                    <div>
                      <button onClick={() => changeAdultCount(-1)}>-</button>
                      <span>{adultCount}</span>
                      <button onClick={() => changeAdultCount(1)}>+</button>
                    </div>
                  </div>

                  <div className="guest-counter">
                    <div>
                      <span>Children :</span><br />
                      <span style={{ fontWeight: "100", fontSize: "12px" }}>0-17 Years</span>
                    </div>
                    <div>
                      <button onClick={() => changeChildCount(-1)}>-</button>
                      <span>{childCount}</span>
                      <button onClick={() => changeChildCount(1)}>+</button>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-4'>
                    <button className="btn_fill" onClick={handleDoneClick}>Done</button> {/* Update the onClick event */}
                  </div>
                </div>
              </div>
            )}

            {showRoomPopup && (
              <div className="guest-popup">
                <div className="guest-popup-content">
                  <div className="guest-counter">
                    <div>
                      <span>Rooms :</span><br />
                      <span style={{ fontWeight: "100", fontSize: "12px" }}>Max 8</span>
                    </div>
                    <div>
                      <button onClick={() => changeRoomCount(-1)}>-</button>
                      <span>{roomCount}</span>
                      <button onClick={() => changeRoomCount(1)}>+</button>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-4'>
                    <button className="btn_fill" onClick={handleRoomDoneClick}>Done</button> {/* Update the onClick event */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      }

      {/* PROPERTY LIST START */}
      <div className='container mt-4'>
        <div className='row'>
          <div className="accommodation-property-list-page">
            {/* Left Side Filters */}
            {mobileFilterOpenPopup&&
            <div className='col-12 col-md-3'>
              <div className="accommodation-property-list-filter-section">
                <h3>Filter by :</h3>
                <hr />
                {/* Popular Filters */}
                <div className="accommodation-property-list-filter-category">
                  <h4>Popular filters</h4>
                  <div>
                    <input type="checkbox" id="5-stars" />
                    <label htmlFor="5-stars">5 stars <span>(56)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="connaught-place" />
                    <label htmlFor="connaught-place">Connaught Place <span>(16)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="superb" />
                    <label htmlFor="superb">Superb: 9+ <span>(288)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="aerocity" />
                    <label htmlFor="aerocity">Aerocity <span>(58)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="free-wifi" />
                    <label htmlFor="free-wifi">Free WiFi <span>(2214)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="balcony" />
                    <label htmlFor="balcony">Balcony <span>(778)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="ac" />
                    <label htmlFor="ac">Air conditioning <span>(2271)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="central-delhi" />
                    <label htmlFor="central-delhi">Central Delhi <span>(425)</span></label>
                  </div>
                  <hr />
                </div>

                {/* Property Type */}
                <div className="accommodation-property-list-filter-category">
                  <h4>Property type</h4>
                  <div>
                    <input type="checkbox" id="hotels" />
                    <label htmlFor="hotels">Hotels <span>(1720)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="love-hotels" />
                    <label htmlFor="love-hotels">Love hotels <span>(2)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="resorts" />
                    <label htmlFor="resorts">Resorts <span>(6)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="holiday-homes" />
                    <label htmlFor="holiday-homes">Holiday homes <span>(3)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="motels" />
                    <label htmlFor="motels">Motels <span>(1)</span></label>
                  </div>
                  <hr />
                </div>

                {/* Facilities */}
                <div className="accommodation-property-list-filter-category">
                  <h4>Facilities</h4>
                  <div>
                    <input type="checkbox" id="parking" />
                    <label htmlFor="parking">Parking <span>(1543)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="restaurant" />
                    <label htmlFor="restaurant">Restaurant <span>(730)</span></label>
                  </div>
                  <div>
                    <input type="checkbox" id="pets-allowed" />
                    <label htmlFor="pets-allowed">Pets allowed <span>(388)</span></label>
                  </div>
                </div>
              </div>
            </div>}

            {/* Right Side Property List */}
            <div className='col-12 col-md-9'>
              <div className="accommodation-property-list-container">
                {propertiesList.map((property, index) => (
                  <Link
                    key={index} // Move the key prop to the Link component
                    to={{
                      pathname: '/accommodation-details',
                      search: `?checkIn=${format(state[0].startDate, 'MM/dd/yyyy')}&checkOut=${format(state[0].endDate, 'MM/dd/yyyy')}&location=${searchLocation}&adults=${adultCount}&children=${childCount}&rooms=${roomCount}`
                    }}
                  >
                    <div className="accommodation-property-list-item" key={index}>
                      <div className="accommodation-property-list-image">
                        <img src={property.imgUrl} alt={`Property ${index + 1}`} />
                      </div>
                      <div className="accommodation-property-list-info">
                        <h3 className="accommodation-property-list-title">
                          {property.title}
                        </h3>
                        <p className="accommodation-property-list-location">{property.location}</p>
                        <div className="accommodation-property-list-features">
                          {property.features.map((feature, i) => (
                            <span key={i}>{feature}</span>
                          ))}
                        </div>
                        <p className="accommodation-property-list-price">{property.price}</p>
                        <div className="accommodation-property-list-buttons">
                          <button className="btn_fill">Book</button>
                          <button className="btn_border">Request a Callback</button>
                        </div>
                        <div className="accommodation-property-list-viewing-status">
                          <span>{property.views}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div >
  );
};

export default AccommodationPropertiesList;

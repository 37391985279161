import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './AccommodationPropertiesDetails.scss';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { DateRange, DefinedRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, format } from 'date-fns'; // Added format for date formatting
import { enUS } from 'date-fns/locale'; // Import the locale from date-fns
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';

const AccommodationPropertiesDetails = () => {
  const [value, setValue] = React.useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  // Extracting values from URL search parameters
  const queryParams = new URLSearchParams(location.search);
  const checkIn = queryParams.get('checkIn');
  const checkOut = queryParams.get('checkOut');
  const address = queryParams.get('location'); 
  const adults = parseInt(queryParams.get('adults'), 10) || 1; // Default to 1 if not provided
  const children = parseInt(queryParams.get('children'), 10) || 0; // Default to 0 if not provided
  const room = parseInt(queryParams.get('rooms'), 10) || 1; // Default to 1 if not provided

  const [searchLocation, setSearchLocation] = useState(address);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showGuestPopup, setShowGuestPopup] = useState(false);
  const [adultCount, setAdultCount] = useState(adults);
  const [childCount, setChildCount] = useState(children);
  const [guestText, setGuestText] = useState(`${adultCount} Adults${childCount > 0 ? `, ${childCount} Children` : ''}`);
  const [showRoomPopup, setShowRoomPopup] = useState(false);
  const [roomCount, setRoomCount] = useState(room);
  const [roomText, setRoomText] = useState(`${roomCount}`);
  const [detailOpenPopup, setDetailOpenPopup] = useState(false);
  const [mobileFilterOpenPopup, setMobileFilterOpenPopup] = useState(false);
  const [direction, setDirection] = useState("horizontal");

  // search location code start
  const addLocation = (location) => {
    setSearchLocation(location)
  }

  // Initialize the state with today's date and a default end date
  const today = new Date();
  const [state, setState] = useState([
    {
      startDate: checkIn ? new Date(checkIn) : today,
      endDate: checkOut ? new Date(checkOut) : addDays(today, 1), // Default end date to one day after today
      key: 'selection'
    }
  ]);

  // State for check-in and check-out dates
  const [selectedCheckIn, setSelectedCheckIn] = useState(checkIn ? format(new Date(checkIn), 'MM/dd/yyyy') : '');
  const [selectedCheckOut, setSelectedCheckOut] = useState(checkOut ? format(new Date(checkOut), 'MM/dd/yyyy') : '');

  // Handle date selection
  const handleDateSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    // Update state
    setState([ranges.selection]);

    // Update the inputs only when both dates are selected
    if (startDate && endDate) {
      setSelectedCheckIn(format(startDate, 'MM/dd/yyyy'));
      setSelectedCheckOut(format(endDate, 'MM/dd/yyyy'));

      // Ensure check-in and check-out dates are different
      if (startDate.getTime() !== endDate.getTime()) {
        setShowCalendar(false);
        setShowGuestPopup(true); // Open guest popup automatically
      }
    }
  };

  // Function to handle adult count increment/decrement
  const changeAdultCount = (amount) => {
    setAdultCount(prevCount => Math.max(1, prevCount + amount));
  };

  // Function to handle child count increment/decrement
  const changeChildCount = (amount) => {
    setChildCount(prevCount => Math.max(0, prevCount + amount));
  };

  // Function to update guest text when "Done" is clicked
  const handleDoneClick = () => {
    let text = `${adultCount} Adults`;
    if (childCount > 0) {
      text += `, ${childCount} Children`;
    }
    setGuestText(text);
    setShowGuestPopup(false); // Close popup after updating text
  };

  // Function to handle room count increment/decrement
  const changeRoomCount = (amount) => {
    setRoomCount(prevCount => Math.max(1, prevCount + amount));
  };

  // Function to update guest text when "Done" is clicked
  const handleRoomDoneClick = () => {
    let text = `${roomCount}`;
    setRoomText(text);
    setShowRoomPopup(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const isPC = window.innerWidth >= 992;
      setDetailOpenPopup(isPC);
      setMobileFilterOpenPopup(isPC);
    };

    // Check screen size on initial render
    handleResize();

    // Add event listener to update state on resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const updateDirection = () => {
      setDirection(window.innerWidth < 768 ? "vertical" : "horizontal");
    };

    // Set initial direction
    updateDirection();

    // Update on resize
    window.addEventListener("resize", updateDirection);
    return () => window.removeEventListener("resize", updateDirection);
  }, []);

  {/* PHONE VIEW TOP DETAILS SECTION START */ }
  const openDetailPopup = () => {
    setMobileFilterOpenPopup(false);
    setDetailOpenPopup(!detailOpenPopup);
  }
  {/* PHONE VIEW TOP DETAILS SECTION END */ }

  return (
    <div>
      {/* SELETE CHECKING AND CHECK OUT DATE START */}
      {/* PHONE VIEW TOP DETAILS SECTION START */}
      <div className='accommodation-property-list-phone-view-detail'>
        <div>
          <h6><b>Vijay Nagar, Gwalior</b></h6>
          <div className='d-flex align-items-center'>
            <p style={{ fontSize: "13px", color: "var(--grey-dark)" }}>26 Nov - 28 Nov</p>, &nbsp;
            <p style={{ fontSize: "13px", color: "var(--grey-dark)" }}>2 Adults, 1 Child</p>&nbsp;
            <span class="material-symbols-outlined" onClick={openDetailPopup} style={{ fontSize: "16px" }}>
              edit
            </span>
          </div>
        </div>
      </div>
      {/* PHONE VIEW TOP DETAILS SECTION END */}

      {detailOpenPopup &&
        <div>
          <div className='accommodation-property-list-checked-date'>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              className='row justify-content-between'
            >
              <div className='col-sm-12 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Area, City, Landmark, Property..." variant="outlined" value={searchLocation} onChange={(e) => addLocation(e.target.value)} /></div>
              <div className='col-6 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Check-In" variant="outlined" onFocus={() => setShowCalendar(true)} value={checkIn} readOnly /></div>
              <div className='col-6 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Check-Out" variant="outlined" onFocus={() => setShowCalendar(true)} value={checkOut} readOnly /></div>
              <div className='col-6 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Guests" variant="outlined" value={`${guestText}`} readOnly onClick={() => setShowGuestPopup("true")} /></div>
              <div className='col-6 col-md-2 accommodation-property-list-checked-date-date'><TextField id="outlined-basic" label="Rooms" variant="outlined" value={`${roomText} Room`} readOnly onClick={() => setShowRoomPopup("true")} /></div>
            </Box>
          </div>

          <div className='d-flex accommodation-property-list-both-calender'>
            {showCalendar && (
              <div className="calendar-overlay" onClick={() => setShowCalendar(false)}>
                <div
                  className="accommodation-property-calendar"
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    maxHeight: "400px", // Adjust based on your needs
                    overflowY: direction === "vertical" ? "scroll" : "visible",
                    overflowX: "hidden",
                  }}
                >
                  <DateRange
                    onChange={handleDateSelect}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction={direction}
                    locale={enUS}
                    minDate={today} // Disable past dates
                  />
                </div>
              </div>
            )}

            {showGuestPopup && (
              <div className="guest-popup">
                <div className="guest-popup-content">
                  <div className="guest-counter">
                    <div>
                      <span>Adults :</span><br />
                      <span style={{ fontWeight: "100", fontSize: "12px" }}>Above 18 Years</span>
                    </div>
                    <div>
                      <button onClick={() => changeAdultCount(-1)}>-</button>
                      <span>{adultCount}</span>
                      <button onClick={() => changeAdultCount(1)}>+</button>
                    </div>
                  </div>

                  <div className="guest-counter">
                    <div>
                      <span>Children :</span><br />
                      <span style={{ fontWeight: "100", fontSize: "12px" }}>0-17 Years</span>
                    </div>
                    <div>
                      <button onClick={() => changeChildCount(-1)}>-</button>
                      <span>{childCount}</span>
                      <button onClick={() => changeChildCount(1)}>+</button>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-4'>
                    <button className="btn_fill" onClick={handleDoneClick}>Done</button>
                  </div>
                </div>
              </div>
            )}

            {showRoomPopup && (
              <div className="guest-popup">
                <div className="guest-popup-content">
                  <div className="guest-counter">
                    <div>
                      <span>Rooms :</span><br />
                      <span style={{ fontWeight: "100", fontSize: "12px" }}>Max 8</span>
                    </div>
                    <div>
                      <button onClick={() => changeRoomCount(-1)}>-</button>
                      <span>{roomCount}</span>
                      <button onClick={() => changeRoomCount(1)}>+</button>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-4'>
                    <button className="btn_fill" onClick={handleRoomDoneClick}>Done</button> {/* Update the onClick event */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      }

      <div className="container accommodation-property-details">
        <div className='row'>
          {/* Left Side */}
          <div className="col-sm-4 col-md-8 accommodation-left">
            <div className="image-grid">
              <div className="grid-item large">
                <img
                  src="https://th.bing.com/th/id/OIP.2t1Ckj7Sz7g-V7Dlz5UARgHaEK?w=1920&h=1080&rs=1&pid=ImgDetMain"
                  alt="Main"
                />
              </div>
              <div className="grid-item">
                <img
                  src="https://wallpaperaccess.com/full/1142283.jpg"
                  alt="Image 2"
                />
              </div>
              <div className="grid-item">
                <img
                  src="https://rimh2.domainstatic.com.au/_ufKSCodWW-aKukEH3IywBbVqbk=/fit-in/1920x1080/filters:format(jpeg):quality(80):no_upscale()/2017963697_1_1_220728_041112-w3840-h2560"
                  alt="Image 3"
                />
              </div>
            </div>

            <div className='d-flex justify-content-between align-items-center mt-3'>
              <h1 className="property-title">House of Bangalore</h1>
              <div className='d-flex align-items-center'>
                <span class="material-symbols-outlined" style={{ borderRadius: "50%", border: "1px solid grey", padding: "5px", fontSize: "17px" }}>
                  bookmark
                </span>
                &nbsp;
                <span class="material-symbols-outlined" style={{ borderRadius: "50%", border: "1px solid grey", padding: "5px", fontSize: "17px" }}>
                  share
                </span>
              </div>
            </div>
            <div className="property-features">
              <span>8 Guests</span> | <span>3 Bathrooms</span>| <span>4 Bedrooms</span>| <span>Private Pool</span>
            </div>
            <div className='d-flex gap-2 mt-2'>
              <div className="property-more-features">
                <span>Minimalist</span>
              </div>
              <div className="property-more-features">
                <span>Beach House</span>
              </div>
              <div className="property-more-features">
                <span>Tropic</span>
              </div>
              <div className="property-more-features">
                <span>Private Pool</span>
              </div>
            </div>
            <br />
            {/* Tab Control */}
            <Box sx={{
              width: '100%',
              overflowX: 'auto', // Enable horizontal scrolling
              whiteSpace: 'nowrap', // Prevent line wrapping
            }}>
              <Tabs value={value} onChange={handleChange} textColor="secondary" indicatorColor="secondary" aria-label="secondary tabs example" variant="scrollable" scrollButtons="auto" >
                <Tab value="one" label="What We Offer" />
                <Tab value="two" label="Description" />
                <Tab value="three" label="Reviews" />
                <Tab value="four" label="About Property" />
              </Tabs>
            </Box>
            <br />
            {/* Render content based on tab selection */}
            <div style={{ padding: "10px" }}>
              {value === 'one' && <div>
                <h6 style={{ fontWeight: "700", letterSpacing: "1px" }}>
                  TOP FACILITIES
                </h6>
                <div className='d-flex flex-wrap'>
                  <div className='d-flex align-items-center  col-6 col-md-4 mt-4' style={{ fontWeight: "400" }}>
                    <span class="material-symbols-outlined" style={{ fontWeight: "200" }}>
                      mode_fan
                    </span>
                    &nbsp;
                    <p>Air Conditioning</p>
                  </div>
                  <div className='d-flex align-items-center col-6 col-md-4 mt-4' style={{ fontWeight: "400" }}>
                    <span class="material-symbols-outlined" style={{ fontWeight: "200" }}>
                      balcony
                    </span>&nbsp;
                    <p>Balcony</p>
                  </div>
                  <div className='d-flex align-items-center col-6 col-md-4 mt-4' style={{ fontWeight: "400" }}>
                    <span class="material-symbols-outlined" style={{ fontWeight: "200" }}>
                      garage
                    </span>&nbsp;
                    <p>Parking</p>
                  </div>
                  <div className='d-flex align-items-center d-flex align-items-center col-6 col-md-4 mt-4 mt-4' style={{ fontWeight: "400" }}>
                    <span class="material-symbols-outlined" style={{ fontWeight: "200" }}>
                      wifi
                    </span>&nbsp;
                    <p>Free Wifi</p>
                  </div>
                  <div className='d-flex align-items-center d-flex align-items-center col-6 col-md-4 mt-4 mt-4' style={{ fontWeight: "400" }}>
                    <span class="material-symbols-outlined" style={{ fontWeight: "200" }}>
                      pool
                    </span>&nbsp;
                    <p>Swimming Pool</p>
                  </div>
                  <div className='d-flex align-items-center d-flex align-items-center col-6 col-md-4 mt-4 mt-4' style={{ fontWeight: "400" }}>
                    <span class="material-symbols-outlined" style={{ fontWeight: "200" }}>
                      deceased
                    </span>&nbsp;
                    <p>Garden</p>
                  </div>
                  <div className='d-flex align-items-center d-flex align-items-center col-6 col-md-4 mt-4 mt-4' style={{ fontWeight: "400" }}>
                    <span class="material-symbols-outlined" style={{ fontWeight: "200" }}>
                      shower
                    </span>&nbsp;
                    <p>Shower</p>
                  </div>
                  <div className='d-flex align-items-center d-flex align-items-center col-6 col-md-4 mt-4 mt-4' style={{ fontWeight: "400" }}>
                    <span class="material-symbols-outlined" style={{ fontWeight: "200" }}>
                      cooking                  </span>&nbsp;
                    <p>Kitchen</p>
                  </div>
                </div>
              </div>}
              {value === 'two' && <p>This property features 4 bedrooms, 3 bathrooms, and can host up to 8 guests. Ideal for family vacations and relaxing getaways. Check out what our guests have to say about their experience at the House of Bangalore. Amazing reviews from happy customers!</p>}
              {value === 'three' && <div>
                <h6 style={{ fontWeight: "700", letterSpacing: "1px" }}>
                  TOP REVIEWS
                </h6>
                <div style={{ border: '1px solid grey', borderRadius: "10px", padding: "20px", marginTop: "20px" }}>
                  <p>
                    The hotel room was clean, spacious, and well-maintained, offering a comfortable stay. The bed was cozy, and the amenities provided were excellent. The staff was friendly and responsive to any requests. The location was convenient, making it easy to explore nearby attractions. Overall, a great experience!</p>
                  <div className='d-flex justify-content-end'>
                    <span>Mr. Rajesh Sinha</span>
                  </div>
                </div>
                <div style={{ border: '1px solid grey', borderRadius: "10px", padding: "20px", marginTop: "20px" }}>
                  <p>
                    The hotel room exceeded expectations with its modern design and attention to detail. The bed was incredibly comfortable, and the view from the window was stunning. Housekeeping did a fantastic job maintaining cleanliness throughout my stay. The bathroom was spacious with high-quality toiletries. I would definitely recommend this hotel for a relaxing stay!</p>
                  <div className='d-flex justify-content-end'>
                    <span>Ms. Anju Singh</span>
                  </div>
                </div>
              </div>}
              {value === 'four' && <p>
                <b>🌟 Hotel Overview</b>
                <br />
                I recently stayed at [Hotel Name], and it was a fantastic experience from start to finish. Located in the heart of the city 🌆, the hotel is perfectly positioned for exploring nearby attractions, making it an ideal base for both leisure and business travelers. The staff were friendly and accommodating, ensuring that every need was met. From the lobby 🏨, which exudes elegance with its modern decor, to the amenities available such as a gym 🏋️‍♂️, spa 💆‍♀️, and rooftop bar 🍹, this hotel has everything you could need.
                <br />
                <br />
                <b>🛏️ Room Experience</b>
                <br />
                The room itself was a blend of comfort and style. Upon entering, I was greeted by a spacious area with a large, plush bed 🛏️ that made sleeping an absolute pleasure. The linens were soft, and pillows provided excellent support. The room also featured a flat-screen TV 📺, a mini fridge 🥂, and a coffee machine ☕, which were great touches.
                <br />
                <br />
                <b>🧼 Bathroom Facilities</b>
                <br />
                The bathroom 🚿 was equally impressive, offering a rain shower that was both invigorating and relaxing. The toiletries 🧴 were of high quality, and fluffy towels added to the luxurious feel.
                <br />
                <br />
                <b>🌐 Tech & Connectivity</b>
                <br />
                The free Wi-Fi 📶 in the room worked flawlessly, which was essential for getting some work done. Additionally, there were plenty of outlets for charging devices 🔌.
                <br />
                Overall, [Hotel Name] provided a wonderful experience with its modern amenities and comfortable, well-designed rooms. I would highly recommend it for anyone visiting the city!</p>}
            </div>
          </div>

          {/* Right Side */}
          <div className="col-sm-4 col-md-8 accommodation-right">
            <iframe
              title="Location Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.1674650693193!2d78.1785!3d26.2182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c3ef7710e0f55%3A0x3ebf36a220a4dbba!2sGwalior%20Fort!5e0!3m2!1sen!2sin!4v1609832303621!5m2!1sen!2sin"
              className="location-map"
              allowFullScreen
              loading="lazy"
            ></iframe>

            <div className="payment-card">
              <h6>₹ 3,350 / Night</h6>
              <div className="payment-details">
                <div className="detail">
                  <span>Total (7 Days) :</span>
                  <span>₹ 3,550</span>
                </div>
                <div className="detail">
                  <span className="d-flex align-items-center">
                    Discount :&nbsp;
                    <span
                      className="material-symbols-outlined"
                      style={{ fontWeight: '200' }}
                    >
                      info
                    </span>
                  </span>
                  <span>-₹ 85</span>
                </div>
                <div className="detail">
                  <span>Service Fee :</span>
                  <span>₹ 35</span>
                </div>
                <hr />
                <div className="detail total">
                  <span>Total Payment:</span>
                  <span>₹ 3,600</span>
                </div>
              </div>
              <Link to="/accommodation-checkout">
                <button className="btn_fill" style={{ width: "100%" }}>Book Now</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default AccommodationPropertiesDetails;

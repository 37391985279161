import React from "react";
import "./SecurityOneNewVisitorPhoneNumber.scss";

const SecurityOneNewVisitorPhoneNumber = () => {
    return (
        <div className="container security-one-new-visitor-phone-container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="security-one-new-visitor-phone-left">
                        <div className="security-one-new-visitor-phone-card">
                            <span class="material-symbols-outlined">
                                contract_delete
                            </span>
                            <h4>No Paperwork</h4>
                        </div>
                        <div className="security-one-new-visitor-phone-card">
                            <span class="material-symbols-outlined">
                                lock
                            </span>
                            <h4>Ensure Safety</h4>
                        </div>
                        <div className="security-one-new-visitor-phone-card">
                            <span class="material-symbols-outlined">
                                history
                            </span>
                            <h4>Real-time Data</h4>
                        </div>
                        <div className="security-one-new-visitor-phone-card">
                            <span class="material-symbols-outlined">
                                credit_card_heart
                            </span>
                            <h4>Affordable Plans</h4>
                        </div>
                    </div>
                </div>

                {/* div for 4 phone number */}
                <div className="col-12 col-md-6 ">
                    <div className="security-one-new-visitor-phone-wrapper">
                        <h2 className="security-one-new-visitor-phone-heading">Welcome New Visitor</h2>
                        <p className="security-one-new-visitor-phone-description">
                            Please enter your phone number to proceed.
                        </p>
                        <form className="security-one-new-visitor-phone-form">
                            <input
                                type="tel"
                                className="security-one-new-visitor-phone-input"
                                placeholder="Enter your phone number"
                            />
                            <button type="submit" className="security-one-new-visitor-phone-button">
                                Next
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SecurityOneNewVisitorPhoneNumber;

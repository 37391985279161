import React from 'react'
import "./SecurityOneRoute.scss";
import { Route, Routes } from "react-router-dom";

import Navbar from '../../../../default components/Navbar';
import NavbarTwo from '../../../../default components/NavbarTwo';
import Footer from '../../../../default components/Footer';
import GlobalRoute from '../../../../global route/GlobalRoute';
import ColorsComponent from '../../../../default components/ColorsComponent';

import SecurityOneHome from '../securityOnePages/SecurityOneHome';
import PGBusinessThreeProfile from '../../../business/businessThree/businessThreePages/PGBusinessThreeProfile';
import FooterUpper from '../../../../default components/FooterUpper';

const SecurityOneRoute = () => {
    return (
        <div>
            <div className="security_one_route_parent">
                <NavbarTwo />
                <Routes>
                    <Route path="/" element={<SecurityOneHome />} />
                    <Route
                        path="/profile"
                        element={<PGBusinessThreeProfile />}
                    />
                </Routes>
                <GlobalRoute />
                <ColorsComponent />
                <FooterUpper/>
                <Footer />
            </div>
        </div>
    )
}

export default SecurityOneRoute

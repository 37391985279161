import { useState } from 'react';
// import { functions } from '../firebase.js';
// import { httpsCallable } from "firebase/functions";
// import axios from 'axios';

export function useRazorpay() {
    const [paymentResponse, setPaymentResponse] = useState({
        transactionID: '',
        paymentStatus: 'Pending'
    });

    async function makePayment(orderId, amount, name, description, email, contact) {
        console.log(orderId);

        const razorpayOptions = {
            key: 'rzp_test_P6e5Ezb1RLGQ48', // Use environment variable in production
            amount: 1 * 100, // Convert amount to paise
            name: 'Nasa Bike Tech',
            description: description,
            email: email,
            contact: contact,
            image: '/App_logo.webp',
            
            handler: async function (response) {
                console.log('Payment success:', response.razorpay_payment_id);
                setPaymentResponse({
                    transactionID: response.razorpay_payment_id,
                    paymentStatus: 'Success'
                });
            },
            
            modal: {
                ondismiss: function () {
                    console.log('Payment cancelled by user');
                    setPaymentResponse({
                        transactionID: '',
                        paymentStatus: 'Cancelled'
                    });
                },
                onfailure: function () {
                    console.log('Payment failed');
                    setPaymentResponse({
                        transactionID: '',
                        paymentStatus: 'Failed'
                    });
                }
            },
            
            prefill: {
                name: name,
                email: email,
                contact: contact,
            },
            
            notes: {
                orderId: orderId,
            },
            
            theme: {
                color: '#348DCB',
            },
        };


        console.log("razorpayOptions",razorpayOptions);
        
        const rzp1 = new window.Razorpay(razorpayOptions);
        await rzp1.open();
    }

    return { makePayment, paymentResponse };
}

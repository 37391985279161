import React from "react";
import "./AccommodationBookingDashboard.scss";
import { useState } from "react";

const AccommodationBookingDashboard = () => {
    const [activeCard, setActiveCard] = useState(0);

    const listings = [
        { img:"https://wallpaperaccess.com/full/5655441.jpg", name: "Rohilla Apartment", price: 1500 },
        { img:"https://th.bing.com/th/id/OIP.MA2KvFKrCpSr7C-B6JPoRwHaE8?rs=1&pid=ImgDetMain", name: "Royal Apartment", price: 1200 },
        { img:"https://th.bing.com/th/id/OIP.txBwbSm-WU4KcLiYc_DzJwHaE8?w=1200&h=800&rs=1&pid=ImgDetMain", name: "Sefron Apartment", price: 2100 },
        { img:"https://i.pinimg.com/736x/74/d3/cc/74d3cc02651fbabb6664da96a49205c5.jpg", name: "Delux Apartment", price: 1900 },
        { img:"https://th.bing.com/th/id/OIP.j7oiq3Vm5csX1ftBBDdtCgHaE6?rs=1&pid=ImgDetMain", name: "Govind Apartment", price: 1600 },
    ];

    return (
        <div className="accommodation-booking-dashboard-container">
            {/* Sidebar */}
            <aside className="accommodation-booking-dashboard-sidebar">
                <div className="accommodation-booking-dashboard-logo">Your Bookings</div>
                <nav className="accommodation-booking-dashboard-nav">
                    <ul>
                        <li><span class="material-symbols-outlined">
                            confirmation_number
                        </span> Booking</li>
                        <li><span class="material-symbols-outlined">
                            chat
                        </span> Message</li>
                        <li className="active"><span class="material-symbols-outlined">
                            list_alt
                        </span> Listing</li>
                        <li><span class="material-symbols-outlined">
                            account_balance_wallet
                        </span> Wallet</li>
                        <li><span class="material-symbols-outlined">
                            calendar_month
                        </span> Calendar</li>
                        <li><span class="material-symbols-outlined">
                            rate_review
                        </span> Reviews</li>
                        <li><span class="material-symbols-outlined">
                            settings
                        </span> Setting</li>
                    </ul>
                </nav>
            </aside>

            {/* Main Content */}
            <main className="accommodation-booking-dashboard-content">
                {/* Top Navigation */}
                <div className="accommodation-booking-dashboard-top-nav">
                    <ul>
                        <li className="active">Upcoming</li>
                        <li>Completed</li>
                        <li>Cancelled</li>
                    </ul>
                    <input
                        type="text"
                        placeholder="Search"
                        className="accommodation-booking-dashboard-search"
                    />
                </div>

                {/* Listings and Details */}
                <div className="accommodation-booking-dashboard-listings">
                    {/* Left Listings */}
                    <div className="accommodation-booking-dashboard-list">
                        {listings.map((listing, index) => (
                            <div
                                key={index}
                                className={`accommodation-booking-dashboard-listing-card ${activeCard === index ? "active" : ""
                                    }`}
                                onClick={() => setActiveCard(index)}
                            >
                                <img
                                    src={listing.img}
                                    alt={listing.name}
                                />
                                <div className="accommodation-booking-dashboard-listing-info">
                                    <div>
                                        <h6>{listing.name}</h6>
                                        <p>Laxmi Nagar, Govindpuri, Sector 11, Delhi</p>
                                        <p className="d-flex align-item-center">
                                            <span className="material-symbols-outlined">
                                                person
                                            </span>
                                            &nbsp;3 Guests ·&nbsp;
                                            <span className="material-symbols-outlined">bed</span>
                                            &nbsp;2 Beds
                                        </p>
                                    </div>
                                    <div>
                                        <button className="btn_border">More</button>
                                        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                                            ₹{listing.price}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Right Details */}
                    <div className="accommodation-booking-dashboard-details">
                        <img
                            src="https://wallpaperaccess.com/full/5655441.jpg"
                            alt="Apartment"
                            className="accommodation-booking-dashboard-details-image"
                        />
                        <h3>Rohilla Apartment</h3>
                        <p className="accommodation-booking-dashboard-details-location">
                            <span class="material-symbols-outlined">location_on</span> Laxmi Nagar, Govindpuri, Sector 11, Delhi
                        </p>
                        <p className="accommodation-booking-dashboard-details-meta">
                            <span class="material-symbols-outlined">person</span> 3 Guests &nbsp;·&nbsp;
                            <span class="material-symbols-outlined">bed</span> 2 Beds
                        </p>
                        <h4>Amenities</h4>
                        <ul className="accommodation-booking-dashboard-amenities">
                            <li><span class="material-symbols-outlined">check_circle</span> 1 Bathroom</li>
                            <li><span class="material-symbols-outlined">check_circle</span> Free WiFi & TV</li>
                            <li><span class="material-symbols-outlined">check_circle</span> Swimming Pool</li>
                            <li><span class="material-symbols-outlined">check_circle</span> Kitchen Facilities</li>
                        </ul>
                        <h4>Photo Gallery</h4>
                        <div className="accommodation-booking-dashboard-gallery">
                                <img
                                        src="https://wallpaperaccess.com/full/5655441.jpg"
                                    className="accommodation-booking-dashboard-gallery-item"
                                />
                                 <img
                                        src="https://d3rr2gvhjw0wwy.cloudfront.net/uploads/activity_galleries/145401/900x600-1-50-bb502b1d0b48b4c247aa700dd2148093.jpg"
                                    className="accommodation-booking-dashboard-gallery-item"
                                />
                                 <img
                                        src="https://th.bing.com/th/id/OIP.5HspR4JQUOug2LaHw7huBwHaE8?rs=1&pid=ImgDetMain"
                                    className="accommodation-booking-dashboard-gallery-item"
                                />
                                 <img
                                        src="https://i.pinimg.com/736x/c3/1e/75/c31e7583f77e7327b6ceb50e99bc839a--hospitality-commercial.jpg"
                                    className="accommodation-booking-dashboard-gallery-item"
                                />
                                 <img
                                        src="https://wallpaperaccess.com/full/5655441.jpg"
                                    className="accommodation-booking-dashboard-gallery-item"
                                />
                                 <img
                                        src="https://wallpaperaccess.com/full/5655441.jpg"
                                    className="accommodation-booking-dashboard-gallery-item"
                                />
                        </div>
                        <br/>
                        <button className="btn_fill">More Details</button>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AccommodationBookingDashboard;

import React from "react";
import "./Counter.css";
import CountUp from "react-countup";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useDocument } from "../firebase files/hooks/useDocument";

const Counter = () => {
  const { user } = useAuthContext();

  // Fetch counters from Firestore
  const { document: counterNumbers, error: counterNumbersError } = useDocument(
    "counters",
    "homeCounter"
  );

  // Safeguard for undefined or null document
  if (!counterNumbers) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }

  // Filter valid counters and prepare data for rendering
  const counters = Object.entries(counterNumbers)
    .filter(([key, value]) => typeof value === "object" && value?.number) // Only keep valid counter entries
    .map(([key, counter], index) => {
      const numberString = counter?.number || "0"; // Fallback to "0" if undefined
      const match = numberString.match(/^(\d+)([a-zA-Z]*)$/); // Separate number and suffix
      const number = match ? parseInt(match[1], 10) : 0; // Extract the numeric part
      const suffix = match ? match[2] : ""; // Extract the suffix

      return {
        id: key, // Use Firestore document ID for key
        iconName: counter?.iconName || "help_outline", // Default icon
        name: counter?.name || "Unknown",
        number,
        suffix,
      };
    });

  return (
    <div className="counter">
      <div className="container mobilecontainer">
        <div
          className={`row ${
            user && user.role === "admin" ? "counter-bg-admin" : "counter-bg"
          }`}
          style={{
            backgroundImage:
              "url('https://firebasestorage.googleapis.com/v0/b/restrofy-f9716.appspot.com/o/master_data%2Fcounter%20bg%20img%2Fcounterbg.png?alt=media&token=23205801-3489-4e63-9d01-eb9020d7ffa2')",
            backgroundColor: "var(--card-bg)",
            color: "var(--black-color)",
          }}
        >
          {counters.map((counter) => (
            <div key={counter.id} className="col-sm-3 mobwidth">
              <div className="counter-item ptop">
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "35px", color: "var(--grey-dark)" }}
                >
                  {counter.iconName}
                </span>
                <h3 className="counter">
                  <span>
                    <CountUp
                      start={0}
                      end={counter.number}
                      duration={3.5}
                      separator=","
                    />
                    {counter.suffix}
                  </span>
                  +
                </h3>
                <p>{counter.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Counter;

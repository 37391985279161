import React, { useState } from 'react';
import './SecurityOneLatestVisitorsHome.scss';

const SecurityOneLatestVisitorsHome = () => {
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');
  const visitors = [
    { id: 1, name: 'Rahul Sharma', img:"https://i.pinimg.com/originals/ca/0f/ab/ca0fab980a0e70a23bb782fe32a95058.jpg", city: 'Delhi', tags: ['Socity', 'delivery'] },
    { id: 2, name: 'Aarav Gupta', img:"https://th.bing.com/th/id/OIP.jTdMyj0VXTbWViHk9B7rYgHaEK?w=321&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7", city: 'Mumbai', tags: ['guest', 'meeting'] },
    { id: 6, name: 'Ananya Das', img:"https://th.bing.com/th/id/OIP.-HTJ5qk6D7HS-LR0IOxi4wHaE7?w=257&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7", city: 'Kolkata', tags: ['vintage', 'visit'] },
    { id: 4, name: 'Sneha Patel', img:"https://th.bing.com/th/id/OIP.ZgD4uvXRHmAmeZgYyW4_bAHaHa?w=159&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7", city: 'Ahmedabad', tags: ['road', 'nature'] },
    { id: 5, name: 'Ravi Kumar', img:"https://th.bing.com/th/id/OIP.E5uj8X0pZsofz7_jRJHT1gHaLH?w=126&h=187&c=7&r=0&o=5&dpr=1.3&pid=1.7", city: 'Hyderabad', tags: ['repair', 'service'] },
    { id: 7, name: 'Manoj Mehta', img:"https://th.bing.com/th/id/OIP.90HZcNCBLL5ZGB1ArJloPAHaE7?w=273&h=182&c=7&r=0&o=5&dpr=1.3&pid=1.7", city: 'Pune', tags: ['trip', 'earth'] },
    { id: 3, name: 'Priya Singh', img:"https://th.bing.com/th/id/OIP.KzBQ8eo6DATZ3ISO5sVD6wHaEK?w=302&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7", city: 'Chennai', tags: ['event', 'conference'] },
    { id: 8, name: 'Deepak Verma', img:"https://th.bing.com/th/id/OIP.09U52RofaxHKqlYoFQxhyAHaE6?w=283&h=187&c=7&r=0&o=5&dpr=1.3&pid=1.7", city: 'Bangalore', tags: ['gadget', 'tech'] },
  ];

  const filteredVisitors = visitors.filter(
    (visitor) =>
      visitor.name.toLowerCase().includes(search.toLowerCase()) ||
      visitor.city.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="container security-one-latest-visitor-home-container">
      <div className="row d-flex justify-content-between align-items-center">
        <div className="security-one-latest-visitor-home-heading col-12 col-md-6"><h2>Latest Visitors</h2></div>
        <div className="filters d-flex gap-3 col-12 col-md-6">
          <input
            type="text"
            placeholder="Search by name or city"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="search-input"
          />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="date-picker"
          />
        </div>
      </div>
      <br />
      <div className="security-one-latest-visitor-home-grid">
        {filteredVisitors.map((visitor) => (
          <div key={visitor.id} className="security-one-latest-visitor-home-card">
            <p className="security-one-latest-visitor-home-city"><span class="material-symbols-outlined">
              person_pin_circle
            </span>{visitor.city}</p>

            <div className="security-one-latest-visitor-home-avatar">
              <div className='d-flex'>
                <img
                  src={visitor.img}
                  alt={visitor.name}
                />
                <div className='d-flex flex-column align-items-start justify-content-center w-100'>
                  <h3 className="security-one-latest-visitor-home-name">{visitor.name}</h3>
                  <h3 className="security-one-latest-visitor-home-date">Entry: 12 Nov 2024</h3>
                </div>
              </div>
            </div>

            <div className="security-one-latest-visitor-home-tags">
              {visitor.tags.map((tag, index) => (
                <span key={index} className="security-one-latest-visitor-home-tag">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecurityOneLatestVisitorsHome;

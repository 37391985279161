import React, { useState, useMemo } from "react";
import "./AllContactData.scss";
import { useCollection } from "../../firebase files/hooks/useCollection";
import { BeatLoader } from "react-spinners";

const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp.toDate());
    const formattedDate = date.toLocaleDateString("en-GB");
    const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
};

const AllContactData = () => {
    const { documents: allEnquiries, error: allEnquiriesError } = useCollection("enquiries");
    const { documents: allUsers, error: allUsersError } = useCollection("users");

    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState("date"); // Default to sorting by date
    const [sortDirection, setSortDirection] = useState("desc"); // Default to descending
    const [selectedEnquiry, setSelectedEnquiry] = useState(null); // State to track selected enquiry for popup
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState("last6months");

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    // Create a map of userId to user details
    const userDetailsMap = useMemo(() => {
        if (!allUsers) return {};
        return allUsers.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
        }, {});
    }, [allUsers]);


    // Calculate date range based on selected time period
    const calculateDateRange = (selectedPeriod) => {
        const now = new Date();
        if (selectedPeriod === "last3months") {
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(now.getMonth() - 3);
            return [threeMonthsAgo, now];
        } else if (selectedPeriod === "last6months") {
            const sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(now.getMonth() - 6);
            return [sixMonthsAgo, now];
        } else if (selectedPeriod === "last1month") {
            const oneMonthAgo = new Date();
            oneMonthAgo.setMonth(now.getMonth() - 1);
            return [oneMonthAgo, now];
        } else if (!isNaN(parseInt(selectedPeriod))) {
            // If selectedPeriod is a year
            const startOfYear = new Date(selectedPeriod, 0, 1);
            const endOfYear = new Date(selectedPeriod, 11, 31, 23, 59, 59);
            return [startOfYear, endOfYear];
        }
        return null;
    };

    const filteredEnquiries = useMemo(() => {
        if (!allEnquiries) return [];

        // Get the date range for the selected period
        const dateRange = calculateDateRange(selectedYear);

        return allEnquiries.filter((enquiry) => {
            const user = userDetailsMap[enquiry.createdBy] || {};
            const { fullName, email } = user;
            const { phoneNumber, issueType, message, createdAt } = enquiry;

            const searchLower = searchQuery.toLowerCase();
            const matchesSearch =
                fullName?.toLowerCase().includes(searchLower) ||
                phoneNumber?.toLowerCase().includes(searchLower) ||
                email?.toLowerCase().includes(searchLower) ||
                issueType?.toLowerCase().includes(searchLower) ||
                message?.toLowerCase().includes(searchLower);

            if (!createdAt || !dateRange) {
                return matchesSearch; // Skip date filtering if no range is defined
            }

            const createdDate = createdAt.toDate();
            return (
                matchesSearch &&
                createdDate >= dateRange[0] &&
                createdDate <= dateRange[1]
            );
        });
    }, [allEnquiries, searchQuery, selectedYear, userDetailsMap]);

    // Sorting logic
    const sortedEnquiries = useMemo(() => {
        return filteredEnquiries.sort((a, b) => {
            const userA = userDetailsMap[a.createdBy] || {};
            const userB = userDetailsMap[b.createdBy] || {};

            if (sortColumn === "name") {
                const nameA = userA.fullName?.toLowerCase() || "";
                const nameB = userB.fullName?.toLowerCase() || "";
                if (nameA < nameB) return sortDirection === "asc" ? -1 : 1;
                if (nameA > nameB) return sortDirection === "asc" ? 1 : -1;
                return 0;
            }

            if (sortColumn === "date") {
                const dateA = a.createdAt?.toDate() || new Date();
                const dateB = b.createdAt?.toDate() || new Date();
                return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
            }

            return 0;
        });
    }, [filteredEnquiries, sortColumn, sortDirection, userDetailsMap]);

    // Handle sorting when clicking on column headers
    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortDirection("desc");
        }
    };


    // Function to convert data to CSV and trigger download
    const downloadCSV = () => {
        const csvHeaders = ["Name", "Issue Type", "Date", "Phone", "Email", "Message"];
        const csvRows = [];

        // Add headers to the CSV
        csvRows.push(csvHeaders.join(","));

        // Add only filtered and sorted enquiry data to the CSV
        sortedEnquiries.forEach((enquiry) => {
            const user = userDetailsMap[enquiry.createdBy] || {};

            const row = [
                user.fullName || "N/A", // Name
                enquiry.issueType || "N/A", // Issue Type
                formatDate(enquiry.createdAt), // Date
                `'${enquiry.phoneNumber || "N/A"}`, // Phone
                user.email || "N/A", // Email
                enquiry.message || "N/A" // Message
            ];

            csvRows.push(row.join(","));
        });

        // Create CSV string
        const csvString = csvRows.join("\n");

        // Trigger download
        const blob = new Blob([csvString], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "filtered_enquiries.csv"; // Update the filename for clarity
        link.click();
    };


    // Handle clicking on an enquiry row or card
    const handleClickEnquiry = (enquiry) => {
        setSelectedEnquiry(enquiry);
    };

    // Close the popup
    const closePopup = () => {
        setSelectedEnquiry(null);
    };


    return (
        <div className="enquiries-data-all-contacts">
            <div className="enquiries-data-header row">
                <div className="enquiries-data-header-info col-12 col-lg-9">
                    <div>
                        <h2>{filteredEnquiries ? filteredEnquiries.length : 0} Enquiries</h2>
                    </div>
                    <div className="button-wrapper">
                        <button className="btn_fill" onClick={downloadCSV}>Download</button>
                    </div>
                </div>
                <div className="search-wrapper d-flex gap-2 col-12 col-lg-3">
                    <input
                        type="search"
                        placeholder="Search Enquiries"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-bar"
                    />

                    <select style={{ border: "1px solid #ccc", borderRadius: "5px" }} value={selectedYear} onChange={handleYearChange}>
                        <option value={currentYear}>{currentYear} (This Year)</option>
                        <option value={currentYear - 1}>{currentYear - 1} (Last Year)</option>
                        <option value="last3months">Last 3 Months</option>
                        <option value="last6months">Last 6 Months</option>
                        <option value="last1month">Last 1 Month</option>
                    </select>
                </div>
            </div>


            {allEnquiriesError && <p className="enquiries-data-error">Error: {allEnquiriesError}</p>}
            {allUsersError && <p className="enquiries-data-error">Error: {allUsersError}</p>}

            {/* PC Table View */}
            <table className="enquiries-data-contacts-table">
                <thead>
                    <tr>
                        <th onClick={() => handleSort("name")} style={{ cursor: "pointer" }}>
                            Name <span className="sort-arrow">{sortColumn === "name" && sortDirection === "asc" ? "▲" : "▼"}</span>
                        </th>                        <th onClick={() => handleSort("issueType")}>Issue Type</th>
                        <th onClick={() => handleSort("date")} style={{ cursor: "pointer" }}>
                            Date <span className="sort-arrow">{sortColumn === "date" && sortDirection === "asc" ? "▲" : "▼"}</span>
                        </th>                        <th>Phone</th>
                        <th>Email</th>
                        <th style={{ width: "30%" }}>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedEnquiries.length > 0 ? (
                        sortedEnquiries.map((enquiry, index) => {
                            const user = userDetailsMap[enquiry.createdBy] || {};
                            return (
                                <tr key={index} onClick={() => handleClickEnquiry(enquiry)}>
                                    <td>{user.fullName || "N/A"}</td>
                                    <td>{enquiry.issueType || "N/A"}</td>
                                    <td>{formatDate(enquiry.createdAt)}</td>
                                    <td>{enquiry.phoneNumber || "N/A"}</td>
                                    <td>{user.email || "N/A"}</td>
                                    <td><p className="enquiries-data-contacts-message">
                                        {enquiry.message || "N/A"}</p></td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr><td colSpan="6">No Enquiry Found</td></tr>
                    )}
                </tbody>
            </table>

            {/* Mobile Grid Layout */}
            <div className="enquiries-data-contacts-grid">
                {sortedEnquiries.length > 0 ? (
                    sortedEnquiries.map((enquiry, index) => {
                        const user = userDetailsMap[enquiry.createdBy] || {};
                        return (
                            <div
                                key={index}
                                className="enquiries-data-contact-card col-12"
                                onClick={() => handleClickEnquiry(enquiry)}
                            >
                                <div className="d-flex justify-content-between">
                                    <h3>{user.fullName || "N/A"}</h3>
                                    <p>{formatDate(enquiry.createdAt)}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p><span class="material-symbols-outlined">
                                        smartphone
                                    </span> {enquiry.phoneNumber || "N/A"}</p>
                                    <p><span class="material-symbols-outlined">
                                        mail
                                    </span>&nbsp;{user.email || "N/A"}</p>
                                </div>
                                <p><strong>Issue Type : </strong> {enquiry.issueType || "N/A"}</p>
                                <p className="enquiries-data-contacts-message"><strong>Message : </strong> {enquiry.message || "N/A"}</p>
                            </div>
                        );
                    })
                ) : (
                    <p className="no-contacts">No Enquiry Found</p>
                )}
            </div>


            {/* Popup for selected enquiry */}
            {selectedEnquiry && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h3>{userDetailsMap[selectedEnquiry.createdBy]?.fullName || "N/A"}</h3>
                            </div>
                            <div className="d-flex align-items-center">
                                <a href={`tel:${selectedEnquiry.phoneNumber}`} >
                                    <img src="./assets/img/phone-call.gif" style={{ height: "25px" }} alt="icon" />
                                </a>

                                &nbsp;
                                <a href={`https://wa.me/${selectedEnquiry.phoneNumber}`} target="blank_">
                                    <img src="/assets/img/icons8-whatsapp.gif" style={{ height: "30px" }} />
                                </a>
                                &nbsp;
                                <div className="d-flex align-items-center justify-content-center close-popup" onClick={closePopup}>
                                    <span class="material-symbols-outlined">
                                        close
                                    </span>
                                </div>
                            </div>
                        </div>


                        <p><strong>Issue Type:</strong> {selectedEnquiry.issueType}</p>
                        <p><strong>Message:</strong> {selectedEnquiry.message}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AllContactData;

import React from 'react'
import AccommodationPropertiesList from './AccommodationPropertiesList'

const PGAccommodationBooking = () => {
  return (
    <div>
      <AccommodationPropertiesList/>
    </div>  
  )
}

export default PGAccommodationBooking

import React from 'react';
import './Dashboard.scss';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from 'react-router-dom';
import { Line, Doughnut } from 'react-chartjs-2';
import { useAuthContext } from '../../firebase files/hooks/useAuthContext';
const Dashboard = () => {
  // Dummy data for the chart
  const {user} = useAuthContext()
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sales Data',
        data: [30, 40, 35, 50, 45, 60],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `₹10,000`;
          },
        },
      },
    },
  };

  // Dummy data for Doughnut chart
  const doughnutData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: 'My Doughnut Chart',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `₹5000`;
          },
        },
      },
    },
  };

  return (
    <div className="main-dashboard-project">
      {/* Sidebar */}
      <aside className="main-dashboard-project-sidebar">
        <p style={{fontSize:"12px", fontWeight:"600", color:"var(--logo-secondary-color)"}}>CONTROL PANEL</p>
        <nav className="main-dashboard-project-sidebar-nav">
          <ul>
            <li className="active"><span class="material-symbols-outlined">
              dashboard
            </span>&nbsp;Dashboard</li>
            <li>
              <Link to={"/orders"}>  
              <span class="material-symbols-outlined">
              list
            </span>
            &nbsp;My Orders
              </Link>
            </li>
            <li><span class="material-symbols-outlined">
              campaign
            </span>&nbsp;Notification</li>
            <li><span class="material-symbols-outlined">
              query_stats
            </span>&nbsp;Tracking</li>
            <li><span class="material-symbols-outlined">
              settings
            </span>&nbsp;Settings</li>
          </ul>
        </nav>
        <hr />
        <p style={{fontSize:"12px", fontWeight:"600", color:"var(--logo-secondary-color)"}}>QUICK LINKS</p>
        <nav className="main-dashboard-project-sidebar-nav">
          <ul>
           <Link to="/all-users">
           <li><span class="material-symbols-outlined">
            groups
            </span>&nbsp;All Users</li>
            </Link>
            <Link to="/sitesettings"><li><span class="material-symbols-outlined">
              tune
            </span>&nbsp;Site Settings</li></Link>
          </ul>
        </nav>
        <div className="main-dashboard-project-sidebar-promo">
          <OwlCarousel
            className="owl-theme"
            loop
            dots={false}
            nav={false}
            autoplay={true}
            autoplayTimeout={3000}
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 1,
              },
              1000: {
                items: 1,
              },
            }}
          >
            <div className="main-dashboard-project-sidebar-promo-item">
              <img src="https://img.freepik.com/premium-vector/special-sale-banner-text-effect_210126-284.jpg" alt="Image 1" />
            </div>
            <div className="main-dashboard-project-sidebar-promo-item">
              <img src="https://image.freepik.com/free-vector/big-diwali-sale-banner-design_1017-15841.jpg" alt="Image 2" />
            </div>
            <div className="main-dashboard-project-sidebar-promo-item">
              <img src="https://img.freepik.com/premium-psd/sale-offer-square-banner-promotion-with-editable-bold-3d-style-effect_333987-2965.jpg" alt="Image 3" />
            </div>
            <div className="main-dashboard-project-sidebar-promo-item">
              <img src="https://i.pinimg.com/736x/ea/30/30/ea303078eb141a026a32a931b8d3917c.jpg" alt="Image 4" />
            </div>
          </OwlCarousel>
        </div>
        <button className="btn_border">Log Out</button>
      </aside>

      {/* Main Content */}
      <main className="main-dashboard-project-main-content">
        <header className="main-dashboard-project-search-section">
          <div>
            <h2>Hii, {user && user.fullName}</h2>
            <p>Today is Wednesday, 10 Sep 2024</p>
          </div>
          <div className="main-dashboard-project-search-bar">
            <button className="btn_fill">Add Booking</button>
          </div>
        </header>

        {/* Upcoming bookings */}
        <section className="main-dashboard-project-popular-section">
          <div className="main-dashboard-project-section-header">
            <h5 style={{ fontWeight: "600" }}>Upcoming Bookings</h5>
            <a href="#" style={{ color: "var(--click-color)", fontWeight: "700" }}>View All</a>
          </div>
          <div className="main-dashboard-project-popular-cards">
            <div className="main-dashboard-project-card">
              <img src="https://th.bing.com/th/id/OIP.hgMs0e1wHs4XBK_iVrVfDAHaHa?pid=ImgDet&w=185&h=185&c=7&dpr=1.3" alt="Hotel" />
              <div className='d-flex justify-content-between align-items-center' style={{ width: "100%" }}>
                <div className="main-dashboard-project-card-details">
                  <h4>Jeans</h4>
                  <p>Linen</p>
                </div>
                <p className="main-dashboard-project-price">₹42 / night</p>
              </div>
            </div>
            <div className="main-dashboard-project-card">
              <img src="https://pluspng.com/img-png/shirt-png-hd-dress-shirt-png-image-dress-shirt-png-914.png" alt="Hotel" />
              <div className='d-flex justify-content-between align-items-center' style={{ width: "100%" }}>
                <div className="main-dashboard-project-card-details">
                  <h4>Shirt</h4>
                  <p>Linen</p>
                </div>
                <p className="main-dashboard-project-price">₹44 / night</p>
              </div>
            </div>
            <div className="main-dashboard-project-card">
              <img src="https://th.bing.com/th/id/OIP.JpHraC4-PZNW1MpH9Df6rgHaHZ?rs=1&pid=ImgDetMain" alt="Hotel" />
              <div className='d-flex justify-content-between align-items-center' style={{ width: "100%" }}>
                <div className="main-dashboard-project-card-details">
                  <h4>Engineering</h4>
                  <p>Workwear</p>
                </div>
                <p className="main-dashboard-project-price">₹38 / night</p>
              </div>
            </div>
            <div className="main-dashboard-project-card">
              <img src="https://img2.exportersindia.com/product_images/bc-full/dir_139/4144345/operation-theater-uniform-1499410953-3109415.jpg" alt="Hotel" />
              <div className='d-flex justify-content-between align-items-center' style={{ width: "100%" }}>
                <div className="main-dashboard-project-card-details">
                  <h4>Hospitality</h4>
                  <p>Workwear</p>
                </div>
                <p className="main-dashboard-project-price">₹45 / night</p>
              </div>
            </div>
          </div>
        </section>

        {/* Most Popular Products */}
        <section className="main-dashboard-project-popular-section">
          <div className="main-dashboard-project-section-header">
            <h5 style={{ fontWeight: "600" }}>Filters</h5>
            <a href="#" style={{ color: "var(--click-color)", fontWeight: "700" }}>View All</a>
          </div>

          {/* Tabs */}
          <div className="main-dashboard-project-tabs">
            <button className="main-dashboard-project-tab main-dashboard-project-active">Pending</button>
            <button className="main-dashboard-project-tab">Confirm</button>
            <button className="main-dashboard-project-tab">Cancelled</button>
          </div>
          <br />

          {/* Card Container */}
          <div className="main-dashboard-project-popular-cards">
            <div className="main-dashboard-project-card">
              <img src="https://th.bing.com/th/id/OIP.hgMs0e1wHs4XBK_iVrVfDAHaHa?pid=ImgDet&w=185&h=185&c=7&dpr=1.3" alt="Hotel" />
              <div className='d-flex justify-content-between align-items-center' style={{ width: "100%" }}>
                <div className="main-dashboard-project-card-details">
                  <h4>Jeans</h4>
                  <p>Linen</p>
                </div>
                <p className="main-dashboard-project-price">₹42 / night</p>
              </div>
            </div>
            <div className="main-dashboard-project-card">
              <img src="https://pluspng.com/img-png/shirt-png-hd-dress-shirt-png-image-dress-shirt-png-914.png" alt="Hotel" />
              <div className='d-flex justify-content-between align-items-center' style={{ width: "100%" }}>
                <div className="main-dashboard-project-card-details">
                  <h4>Shirt</h4>
                  <p>Linen</p>
                </div>
                <p className="main-dashboard-project-price">₹44 / night</p>
              </div>
            </div>
            <div className="main-dashboard-project-card">
              <img src="https://th.bing.com/th/id/OIP.JpHraC4-PZNW1MpH9Df6rgHaHZ?rs=1&pid=ImgDetMain" alt="Hotel" />
              <div className='d-flex justify-content-between align-items-center' style={{ width: "100%" }}>
                <div className="main-dashboard-project-card-details">
                  <h4>Engineering</h4>
                  <p>Workwear</p>
                </div>
                <p className="main-dashboard-project-price">₹38 / night</p>
              </div>
            </div>
            <div className="main-dashboard-project-card">
              <img src="https://img2.exportersindia.com/product_images/bc-full/dir_139/4144345/operation-theater-uniform-1499410953-3109415.jpg" alt="Hotel" />
              <div className='d-flex justify-content-between align-items-center' style={{ width: "100%" }}>
                <div className="main-dashboard-project-card-details">
                  <h4>Hospitality</h4>
                  <p>Workwear</p>
                </div>
                <p className="main-dashboard-project-price">₹45 / night</p>
              </div>
            </div>
          </div>
        </section>

        {/* Best Products Section */}
        <section className="main-dashboard-project-best-products-section">
          <div className="main-dashboard-project-section-header">
            <h5 style={{ fontWeight: "600" }}>Best Products</h5>
            <a style={{ color: "var(--click-color)", fontWeight: "700" }}>View All</a>
          </div>
          <div className="main-dashboard-project-product-cards">
            <div className="main-dashboard-project-product-card">
              <img src="https://th.bing.com/th/id/OIP.n7xrg_7fIwH67JjjsCbOawAAAA?w=227&h=188&c=7&r=0&o=5&dpr=1.3&pid=1.7" alt="Product 1" />
              <h5>Product 1</h5>
              <p>Description of Product</p>
              <p className="main-dashboard-project-price">₹42.72</p>
            </div>
            <div className="main-dashboard-project-product-card">
              <img src="https://th.bing.com/th/id/OIP.zjwP4ktSW4H6lqibvpY1EwHaE8?w=255&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7" alt="Product 2" />
              <h5>Product 2</h5>
              <p>Description of Product</p>
              <p className="main-dashboard-project-price">₹38.42</p>
            </div>
            <div className="main-dashboard-project-product-card">
              <img src="https://th.bing.com/th/id/OIP.zjwP4ktSW4H6lqibvpY1EwHaE8?w=255&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7" alt="Product 2" />
              <h5>Product 2</h5>
              <p>Description of Product</p>
              <p className="main-dashboard-project-price">₹38.42</p>
            </div>
            <div className="main-dashboard-project-product-card">
              <img src="https://th.bing.com/th/id/OIP.zjwP4ktSW4H6lqibvpY1EwHaE8?w=255&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7" alt="Product 2" />
              <h5>Product 2</h5>
              <p>Description of Product</p>
              <p className="main-dashboard-project-price">₹38.42</p>
            </div>
            <div className="main-dashboard-project-product-card">
              <img src="https://th.bing.com/th/id/OIP.zjwP4ktSW4H6lqibvpY1EwHaE8?w=255&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7" alt="Product 2" />
              <h5>Product 2</h5>
              <p>Description of Product</p>
              <p className="main-dashboard-project-price">₹38.42</p>
            </div>
          </div>
        </section>
      </main>

      {/* chart Section */}
      <aside className="main-dashboard-project-chart">
        {/* use chart here */}
        <Line data={data} options={options} />
        <br />
        <Doughnut data={doughnutData} options={doughnutOptions} />
      </aside>
    </div>
  );
};

export default Dashboard;

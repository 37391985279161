import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCollection } from "../../firebase files/hooks/useCollection";
import AddMedia from "../../default components/AddMedia";
import AboutUsImages from "../../default components/AboutUsImages";
import "./PGAboutUs.css";
import { useDocument } from "../../firebase files/hooks/useDocument";
import Team from "../../default components/Team";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import CorporateTwoClients from "../../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoClients/CorporateTwoClients";
import RichTextEditor from "react-rte";
import { useFirestore } from "../../firebase files/hooks/useFirestore";

const PGAboutUs = () => {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();

  const { documents: dbTeamDocuments, error: dbTeamError } = useCollection(
    "media",
    ["category", "==", "team"]
  );

  const { document: organisationDocument, errors: organisationDocumentError } =
  useDocument("settings", "organisation");

const { documents: dbMediaDocuments, error: dbMediaError } = useCollection(
  "media",
  ["pageName", "==", "home"]
);

    const { updateDocument, response } = useFirestore("settings");

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [hasChanges, setHasChanges] = useState(false);

  
    // Load the data into the editor when the document is fetched
    useEffect(() => {
      if (organisationDocument) {
          setValue(RichTextEditor.createValueFromString(organisationDocument.aboutUs, "html"));
      }
  }, [organisationDocument]);

   // Handle changes in the editor
   const handleEditorChange = (newValue) => {
    setValue(newValue);

    // Track changes by comparing current editor value with original content
    setHasChanges(newValue.toString("html") !== document.pageContent);
};

// Save changes to Firestore
const handleSaveClick = async () => {
    try {
        const content = value.toString("html");

        // Update the document in Firestore
        await updateDocument("organisation", { aboutUs: content });

        if (response.error) {
            console.error("Error updating document:", response.error);
        } else {
            console.log("Document updated successfully");
            setHasChanges(false);
        }
    } catch (error) {
        console.error("An error occurred while updating the document:", error);
    }
};


  const aboutusvideo =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (e) => e.section == "aboutus" && e.mediaType == "video"
    );

  return (
    <div>
      <div className="pvcy_polcy_head_div">
        <h2 className="faq_services_heading_p">About Us</h2>
      </div>
      <div className="medium_margin_div"></div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="d-flex flex-column">
              <div>
                <div className="faq_services_heading">
                  <p>About Us</p>
                  <h1 id="faq_top_heading">
                    Our Journey, Our Vision: Exploring Who We Are
                  </h1>
                </div>
                <div className="corporateTwo-policy-body">
                  {user && user.role === "admin" && (
                    <div>
                      <RichTextEditor value={value} onChange={handleEditorChange} />
                      {hasChanges && (
                        <button className="product_edit_save_btn" onClick={handleSaveClick}>
                          Save
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: organisationDocument &&
                        organisationDocument.aboutUs.toString("html"),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <AboutUsImages
              category="About Us Images"
              pageName="aboutus"
              section="aboutusimages"
              addMoreVideosButton={false}
              addMoreTextContentButton={false}
            />
          </div>
        </div>
        <div className="small_margin_div"></div>

        <div className="row d-flex justify-content-center">
          <div className="about_video_sect_upper_child">
            <h3>Lets Meet Our Team</h3>
            <p>
              A team is a group of individuals working together to achieve their
              goal.
            </p>
          </div>
          <div className="small_margin_div"></div>
          <video
            width="100%"
            controls
            autoPlay
            loop
            muted
            className="aboutus_video"
          >
            {aboutusvideo && aboutusvideo.length > 0 && (
              <source src={aboutusvideo[0].mediaUrl} type="video/mp4" />
            )}
            Your browser does not support the video tag.
          </video>
          <AddMedia
            category="video"
            pageName="home"
            section="video"
            // addMoreImagesButton={false}
            addMoreVideosButton={false}
            addMoreTextContentButton={false}
          />
        </div>
        <div className="large_margin_div"></div>
      </div>

      {dbTeamDocuments && (
        user && user.role === "admin" ||
        (dbTeamDocuments.length > 0 && dbTeamDocuments.some(doc => doc.status === "active"))
      ) && (
          <div className="about_team_sect">
            <div className="small_padding_div"></div>
            <div className="about_team_sect_upper_child">
              <h3>Lets Meet Our Team</h3>
              <p>
                A team is a group of individuals working together to achieve their
                goal.
              </p>
            </div>
            <div>
              <Team
                category="team"
                pageName="home"
                section="team"
                addMoreVideosButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          </div>
        )}



      <div className="small_margin_div"></div>
      {/* Our Partner section */}
      {/* <PartnerSlider
        category="partners"
        pageName="home"
        section="partners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      /> */}
      <CorporateTwoClients
        category="partners"
        pageName="about"
        section="partners"
        addMoreVideosButton={false}
        addMoreImagesButton={false}
        addMoreTextContentButton={false}
      />
    </div>
  );
};

export default PGAboutUs;

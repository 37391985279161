import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDocument } from "../firebase files/hooks/useDocument";
import "./NavbarBottom.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
export default function NavbarBottom() {
  const location = useLocation(); // Get the current location
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const { document: appTypeDetails, error: appTypeDetailserror } = useDocument(
    "settings",
    "AppType"
  );
  const { document: NavMenuNames, error: NavMenuNameserror } = useDocument(
    "settings",
    "EditNavbarMenus"
  );

  const { document: pageControlDoc, error: pageControlDocError } = useDocument(
    "settings",
    "pagesControl"
  );

  const { document: dbTemplatesPaletteDocuments, error: dbTemplatesPaletteError } =
    useDocument("settings", "themePalette");

  const showProfile = () => {
    navigate("/profile");
  };

  const showHome = () => {
    navigate("/")
  };

  const showSecondPage = () => {
    {
      pageControlDoc && String(pageControlDoc.products) === "true" && String(appTypeDetails.apptype) !== "lite" ? (
        navigate("/products")
      ) :
        navigate("/gallery")
    }
  };

  const showThirdPage = () => {
    navigate("/customerdashboard");
  };

  const showFourthPage = () => {
    navigate("/contact");
  };

  const showBookingPage = () => {
    navigate("/orders")
  }

  const showGallery = () => {
    navigate("/gallery")
  }

  const showFifthPage = () => {
    {
      pageControlDoc && String(pageControlDoc.accommodationBooking) === "true" && String(appTypeDetails.apptype) !== "lite" ? (
        navigate("/accommodation-booking")
      ) :
        navigate("/gallery")
    }
  };



  //Menus as per role
  let firstMenuIcon = "";
  let firstMenu = "";
  let secondMenuIcon = "";
  let secondMenu = "";
  let thirdMenuIcon = "";
  let thirdMenu = "";
  let fourthMenu = "";
  let fourthMenuIcon = "";
  let fifthMenu = "";
  let fifthMenuIcon = "";

  if (!user) {
    firstMenuIcon = "home";
    firstMenu = NavMenuNames && NavMenuNames.firstMenuName;
    secondMenuIcon = "local_mall";
    secondMenu = NavMenuNames && NavMenuNames.secondMenuName;
    thirdMenuIcon = "insert_chart";
    thirdMenu = "Dashboard";
    fourthMenuIcon = "perm_phone_msg";
    fourthMenu = NavMenuNames && NavMenuNames.thirdMenuName;
    fifthMenuIcon = "bed";
    fifthMenu = NavMenuNames && NavMenuNames.seventhMenuName;
  } else {
    firstMenuIcon = "home";
    firstMenu = NavMenuNames && NavMenuNames.firstMenuName;
    secondMenuIcon = "local_mall";
    secondMenu = NavMenuNames && NavMenuNames.secondMenuName;
    thirdMenuIcon = "insert_chart";
    thirdMenu = "Dashboard";
    fourthMenuIcon = "perm_phone_msg";
    fourthMenu = NavMenuNames && NavMenuNames.thirdMenuName;
    fifthMenuIcon = "bed";
    fifthMenu = NavMenuNames && NavMenuNames.seventhMenuName;
  }
  return (
    <div className="small navbar-mobile-bottom"
      style={{
        background: dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "diwaliThemePalette"
          ? "linear-gradient(to right, #F57000, #F5BF00)"
          : dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "defaultThemePalette"
            ? "var(--page-bg)"
            : dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "holiThemePalette"
              ? `radial-gradient(circle at 10% 20%, rgba(255, 0, 0, 1), transparent 20%),
           radial-gradient(circle at 80% 30%, rgba(0, 255, 0, 1), transparent 20%),
           radial-gradient(circle at 40% 70%, rgba(0, 0, 255, 1), transparent 20%),
           radial-gradient(circle at 90% 80%, rgba(255, 255, 0, 1), transparent 20%),
           radial-gradient(circle at 30% 90%, rgba(255, 165, 0, 1), transparent 20%),
           radial-gradient(circle at 20% 50%, rgba(255, 0, 255, 1), transparent 15%),
           radial-gradient(circle at 70% 40%, rgba(0, 255, 255, 1), transparent 25%),
           radial-gradient(circle at 50% 50%, rgba(255, 192, 203, 1), transparent 30%),
           radial-gradient(circle at 60% 60%, rgba(173, 216, 230, 1), transparent 25%),
           radial-gradient(circle at 85% 20%, rgba(255, 223, 186, 1), transparent 20%)`
              : null
      }}
    >
      <div className="navbar-mobile-bottom-menu" id="divBottomNavBar">
        <div
          className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/" ? "active_bottom_menu" : ""
            } `}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={showHome}
        >
          <span className="material-symbols-outlined">{firstMenuIcon}</span>
          <small>{firstMenu}</small>
        </div>

        {pageControlDoc && String(pageControlDoc.products) === "true" && (
          <div
            className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/products" ? "active_bottom_menu" : ""
              } `}
            style={{ display: "flex", flexDirection: "column" }}
            onClick={showSecondPage}
          >
            <span className="material-symbols-outlined">{secondMenuIcon}</span>
            <small>{secondMenu}</small>
          </div>
        )}

        {/* ACCOMMODATION BOOKING MENU */}
        {pageControlDoc && String(pageControlDoc.accommodationBooking) === "true" && (
          <div
            className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/accommodation-booking" ? "active_bottom_menu" : ""
              } `}
            style={{ display: "flex", flexDirection: "column" }}
            onClick={showFifthPage}
          >
            <span className="material-symbols-outlined">{fifthMenuIcon}</span>
            <small>{fifthMenu}</small>
          </div>
        )}

        {pageControlDoc && pageControlDoc.login && (
          <div className="bmp_single">
            <Link
              to="/profile"
              className="profile_icon bm_single"
            >
              {user && user ? <img className="bottom-nav-profil-pic" src={user && user.photoURL} alt="user photo" />
                : (<span className="material-symbols-outlined">person</span>)}
            </Link>
          </div>
        )}

        {user ? (
          pageControlDoc && pageControlDoc.orders === true ? (
            <div
              className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/orders" ? "active_bottom_menu" : ""}`}
              style={{ display: "flex", flexDirection: "column" }}
              onClick={showBookingPage}
            >
              <span className="material-symbols-outlined">list_alt</span>
              <small>My Orders</small>
            </div>
          ) : (
            <div
              className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/gallery" ? "active_bottom_menu" : ""}`}
              style={{ display: "flex", flexDirection: "column" }}
              onClick={showGallery}
            >
              <span className="material-symbols-outlined">image</span>
              <small>Gallery</small>
            </div>
          )
        ) : (
          <div
            className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/gallery" ? "active_bottom_menu" : ""}`}
            style={{ display: "flex", flexDirection: "column" }}
            onClick={showGallery}
          >
            <span className="material-symbols-outlined">image</span>
            <small>Gallery</small>
          </div>
        )}


        <div
          className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/contact" ? "active_bottom_menu" : ""
            } `}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={showFourthPage}
        >
          <span className="material-symbols-outlined">{fourthMenuIcon}</span>
          <small>{fourthMenu}</small>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useAuthContext } from './useAuthContext';
import { projectFirestore, timestamp } from '../../firebase config/config';
import { useFirestore } from './useFirestore';


const useOrders = () => {
    const [orders, setOrders] = useState([]);
    const { user } = useAuthContext();
    const { addDocument, updateDocument, deleteDocument } = useFirestore('orders');

    useEffect(() => {
        if (user) {
            const unsubscribe = projectFirestore
                .collection('orders')
                .where('userId', '==', user.uid)
                .onSnapshot((snapshot) => {
                    const items = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setOrders(items);
                });

            return () => unsubscribe();
        }
    }, [user])

    const createOrder = async (orderProducts, orderPrice, address, paymentMethod) => {
        const orderData = {
            products: orderProducts,
            totalPrice: orderPrice,
            shippingAddress: address,
            paymentMethod: paymentMethod,
            orderStatus: "pending",

            //START PAYMENT RELATED FIELDS
            transactionid: "",
            transactionstatus: "",
            transactiontimestamp: "",
            transactionamount: "",
            isrefunded: "",
            refundedamount: "",
            refundedtimestamp: "",
            refundstatus: "",
            //END PAYMENT RELATED FIELDS

            createdAt: timestamp.fromDate(new Date()),
            userId: user.uid
        };

        try {
            const addedDocRef = await addDocument(orderData);
            const addedDocSnap = await addedDocRef.get();
            return { id: addedDocSnap.id, ...addedDocSnap.data() };
        } catch (error) {
            console.error("Error creating order:", error);
            return null;
        }
    }

    const getOrderInfo = async (id) => {
        const order = orders.find((order) => order.id === id);
        return order;
    }

    const updateOrder = async (id, status) => {
        try {
            await updateDocument(id, { orderStatus: status })
        } catch (err) {
            console.log("Error updating order", err)
        }
    }

    return { orders, createOrder, getOrderInfo, updateOrder };

}

export default useOrders;

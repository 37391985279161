import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const CustomSnackbar = ({ open, message, severity, onClose }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            <Alert onClose={onClose} severity={severity} sx={{
                width: '100%', 
                backgroundColor: '#ff3333', // Red background
                color: 'white',            // White text
                '& .MuiAlert-icon': {
                    color: 'white'           // White icon
                },
                '& .MuiAlert-action': {
                    color: 'white'           // White close button
                }
            }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;

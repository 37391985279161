import React, { useState, useEffect, useRef } from "react";
import "./BusinessThreeFaq.scss";

const BusinessThreeFaq = ({ id }) => {
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const typingRef = useRef(null);
  const headingRef = useRef(null);

  const faqs = [
    {
      question: "What is your return policy?",
      answer:
        "You can book a room by selecting your preferred dates, location, and room type on our website. Once you've chosen, click Book Now fill in your details, and complete the payment process to confirm your reservation",
    },
    {
      question: "What is the cancellation policy?",
      answer:
        "Our cancellation policy varies by booking type. Generally, you can cancel free of charge up to 24 hours before your check-in date. For detailed terms, please refer to your booking confirmation or contact customer support.",
    },
    {
      question: "What amenities are included with the room?",
      answer:
        "Room amenities typically include free Wi-Fi, air conditioning, toiletries, and complimentary breakfast. Additional services like room service or parking may vary by location and can be checked on the room details page.",
    },
  ];

  const preprocessAnswer = (answer) => {
    return answer
      .split("\n")
      .map((line) => (line.trim().length > 0 ? " " + line : line))
      .join("\n");
  };

  const handleFaqClick = (index) => {
    if (index !== selectedFaq) {
      setSelectedFaq(index);
      setDisplayAnswer("");
    }
  };

  useEffect(() => {
    if (selectedFaq !== null) {
      const answer = preprocessAnswer(faqs[selectedFaq].answer);
      let i = 0;

      if (typingRef.current) {
        clearTimeout(typingRef.current);
      }

      const typeAnswer = () => {
        if (i < answer.length) {
          setDisplayAnswer((prev) => prev + answer.charAt(i));
          i++;
          typingRef.current = setTimeout(typeAnswer, 10);
        }
      };

      typeAnswer();
    }
  }, [selectedFaq]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);

  return (
    <div className="business-three-faq-container-parent" id={id}>
      <div className="small_padding_div"></div>
      <div className="container business-three-box-container">
        <div ref={headingRef} className="business-three-heading-div">
          <h3>
            <span>FAQ</span> What You Need to Know
          </h3>
        </div>
        <div className="business-three-template-view-more-icon">
          <span class="material-symbols-outlined">arrow_forward_ios</span>
        </div>
      </div>
      <div className="container business-three-faq-container">
        <div className="row">
          <div className="col-12 col-md-6 business-three-faq-questions">
            {faqs.map((faq, index) => (
              <div
                key={index}
                onClick={() => handleFaqClick(index)}
                className={`business-three-faq-question ${
                  selectedFaq === index ? "active" : ""
                }`}
              >
                {faq.question}
                <span class="material-symbols-outlined">arrow_forward_ios</span>
              </div>
            ))}
          </div>
          <div className="col-12 col-md-6 business-three-faq-answer">
            {selectedFaq == null ? (
              <div className="business-three-faq-answer-content">
                <h5>
                  World class
                  <span style={{ fontWeight: "700" }}>
                    {" "}
                    DRY CLEAN & LAUNDRY{" "}
                  </span>
                  service,
                  <span style={{ fontWeight: "700" }}> NOW IN INDIA </span>
                </h5>
                <hr />
                World Renowned Machines German Eco Friendly Cleaning Solutions
                <br />
                Upto 25% OFF On First Order
                <hr />
                <button className="btn_fill">More</button>
              </div>
            ) : (
              <div className="business-three-faq-answer-content">
                {displayAnswer}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="small_padding_div"></div>
    </div>
  );
};

export default BusinessThreeFaq;

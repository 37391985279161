import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PartnerSlider from "../../default components/PartnerSlider";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import RichTextEditor from "react-rte";
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();
  const { updateDocument, response } = useFirestore("pages-data");
  const { document: pagesTextDataDocument, error: pagesTextDataError } =
    useDocument("pages-data", "privacyPolicy");

  // State to hold the editor value
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [hasChanges, setHasChanges] = useState(false);

  // Load the data into the editor when the document is fetched
  useEffect(() => {
    if (pagesTextDataDocument) {
      setValue(
        RichTextEditor.createValueFromString(
          pagesTextDataDocument.policyBody,
          "html"
        )
      );
    }
  }, [pagesTextDataDocument]);

  // Function to handle save click
  const handleSaveClick = async () => {
    try {
      const content = value.toString("html");

      // Update the document in Firestore
      await updateDocument("privacyPolicy", { policyBody: content });

      if (response.error) {
        console.error("Error updating document:", response.error);
      } else {
        console.log("Document updated successfully");
        setHasChanges(false); // Reset changes tracking after successful save
      }
    } catch (error) {
      console.error("An error occurred while updating the document:", error);
    }
  };

  // Function to handle changes in the editor
  const handleEditorChange = (newValue) => {
    setValue(newValue);
    // Compare the current editor value with the original document to detect changes
    if (newValue.toString("html") !== pagesTextDataDocument.policyBody) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };

  return (
    <div>
      <div className="top_header_pg pa_bg">
        <div className="pa_inner_page info_in_ul_li max-991">
          <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
            <h2>Privacy Policy</h2>
            <p>
              Welcome to our Privacy Policy. We are committed to protecting your
              privacy and ensuring the security of your personal information.
            </p>
          </div>

          <div className="corporateTwo-policy-body">
    {user && user.role == "admin" && (
      <div>
        <RichTextEditor value={value} onChange={handleEditorChange} />
        {hasChanges && (
          <button
            className="product_edit_save_btn"
            onClick={handleSaveClick}
          >
            Save
          </button>
        )}
      </div>
    )}
    <p
      dangerouslySetInnerHTML={{
        __html:
          pagesTextDataDocument &&
          pagesTextDataDocument.policyBody.toString("html"),
      }}
    ></p>
  </div>
          {/* <ul className="pvcy_polcy_head_ul_div">
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">info</span> &nbsp;
                <b> Information We Collect</b>
              </div>

              <p>
                We collect information you provide when using our platform, such
                as contact details and property-related data, ensuring a
                personalized and efficient user experience.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              {" "}
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">quiz</span> &nbsp;
                <b>How We Use Your Information</b>{" "}
              </div>
              <p>
                Your data is used to facilitate property transactions, enhance
                user experience, and communicate important updates. We do not
                sell your information to third parties.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">lock</span> &nbsp;
                <b>Data Security</b>{" "}
              </div>
              <p>
                We employ industry-standard security measures to protect your
                information. However, no method is 100% secure; use app at your
                own risk.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">cookie</span> &nbsp;
                <b>Cookies</b>
              </div>
              <p>
                Our app uses cookies to enhance user experience and analyze
                usage patterns. You can manage cookie preferences in your
                browser settings.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">alt_route</span> &nbsp;
                <b>Third-Party Links</b>
              </div>
              <p>
                Our platform may contain links to third-party sites. We are not
                responsible for their privacy practices; review their policies
                independently.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">update</span> &nbsp;
                <b>Updates to Privacy Policy</b>
              </div>
              <p>
                We may update our Privacy Policy. Check this page regularly for
                any changes.
              </p>
            </li>
          </ul> */}
        </div>
      </div>
      {/* Our Partner section */}
      <PartnerSlider
        category="partners"
        pageName="home"
        section="partners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
    </div>
  );
};

export default PrivacyPolicy;

import React , {useState,useEffect} from 'react';
import useWishlist from '../firebase files/hooks/useWishlist';
import useCart from '../firebase files/hooks/useCart';
import { useCollection } from '../firebase files/hooks/useCollection';
import { useDocument } from '../firebase files/hooks/useDocument';
import Skeleton from "react-loading-skeleton";
import { useNavigate } from 'react-router-dom';
import './Wishlist.scss';

const Wishlist = () => {
    const navigate = useNavigate();
    const { wishlistItems, removeFromWishlist } = useWishlist();
    const { addToCart } = useCart();
    const { documents: productDocuments, error: productError } = useCollection("products")
    const { document: currencyDocument, error: currencyError } = useDocument(
        "settings",
        "currency"
    );

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (currencyDocument && productDocuments) {
            setIsLoading(false);
        }
    }, [currencyDocument, productDocuments]);

    const handleAddToCart = async (productId) => {
        await addToCart(productId);
        removeFromWishlist(productId);
    };

    const getProductInfo = (productId) => {
        const product = productDocuments?.find(product => product.id === productId)
        return product;
    }

    if (isLoading) {
            return (
              <div style={{ height: "100vh", padding: "10px" }}>
                <Skeleton count={5} height={100} className="mb-4" />
              </div>
            );
          }

    return (
        <div className="wishlist-container">
            <h1>My Wishlist</h1>
            {wishlistItems.length === 0 ? (
                <div className="empty-wishlist-container">
                    <p className="empty-wishlist">Your wishlist is empty</p>
                    <button onClick={() => navigate("/products")}>Explore Our Products</button>
                </div>
            ) : (
                <div className="wishlist-grid">
                    {wishlistItems.map((item) => (
                        <div key={item.id} className="wishlist-item">
                            <div className="item-image">
                                <img src={getProductInfo(item.productId)?.images[0]} alt={getProductInfo(item.productId)?.name} />
                            </div>
                            <div className="item-details">
                                <h3>{getProductInfo(item.productId)?.name}</h3>
                                <p className="description">{getProductInfo(item.productId)?.short_description}</p>
                                <p className="price">{currencyDocument.symbol} {getProductInfo(item.productId)?.price}</p>
                                <div className="item-actions">
                                    <button
                                        className="add-to-cart"
                                        onClick={() => handleAddToCart(item.productId)}
                                    >
                                        Add to Cart
                                    </button>
                                    <button
                                        className="remove"
                                        onClick={() => removeFromWishlist(item.productId)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Wishlist;
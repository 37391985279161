import { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase config/config';

export const useAdminOrders = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const unsubscribe = projectFirestore
            .collection('orders')
            .onSnapshot((snapshot) => {
                const items = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setOrders(items);
            });

        return () => unsubscribe();
    }, []);

    return { orders };
};
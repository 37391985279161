import React from 'react'
import "./SecurityOneHome.scss";
import SecurityOneVideoSection from '../securityOneComponent/SecurityOneVideoSection'
import SecurityOneNewVisitorPhoneNumber from '../securityOneComponent/SecurityOneNewVisitorPhoneNumber';
import SecurityOneLatestVisitorsHome from '../securityOneComponent/SecurityOneLatestVisitorsHome';
const SecurityOneHome = () => {
    return (
        <div>
            <SecurityOneVideoSection/>
            <SecurityOneNewVisitorPhoneNumber/>
            <SecurityOneLatestVisitorsHome/>
            <br/>
        </div>
    )
}

export default SecurityOneHome

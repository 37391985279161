import React, { useState } from "react";
import { useAuthContext } from "../../../firebase files/hooks/useAuthContext";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { timestamp } from "../../../firebase config/config";
import RichTextEditor from "react-rte";
import { useDocument } from "../../../firebase files/hooks/useDocument";

import "./AddNotification.scss";

const AddNotification = ({ onClose }) => {
  const { user } = useAuthContext();
  const { addDocument, response } = useFirestore("notifications");
  const [headingEditor, setHeadingEditor] = useState(RichTextEditor.createEmptyValue());
  const [detailsEditor, setDetailsEditor] = useState(RichTextEditor.createEmptyValue());
  const [newSelectedOptionContent, setNewSelectedOptionContent] = useState("Offer");
  const [notificationDate, setNotificationDate] = useState(new Date());
  const [selectedRole, setSelectedRole] = useState("all");
  const { document: rolesDocument } = useDocument("m_roles", "rolesDoc");

  const handleAddTextContent = async (e) => {
    e.preventDefault();

    const newNotification = {
      heading: headingEditor.toString("html"), // Convert heading to HTML
      notificationType: newSelectedOptionContent,
      fullDetail: detailsEditor.toString("html"), // Convert details to HTML
      status: "active",
      visibleFor: selectedRole,
      tillDate: timestamp.fromDate(new Date(notificationDate)),
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(newNotification);

    if (!response.error) {
      setHeadingEditor(RichTextEditor.createEmptyValue());
      setDetailsEditor(RichTextEditor.createEmptyValue());
      setNewSelectedOptionContent("Offer");
      setNotificationDate(new Date());
      onClose();
    }
  };

  // Calculate tomorrow's date for the min attribute
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <div className="add-notification-modal">
      <div className="modal-content">
        <div className="d-flex justify-content-center">
          <h5 style={{ color: "var(--heading-color)" }}><b>Add Notification</b></h5>
        </div>
        <hr />
        <div className="notification-tabs">
          {["Offer", "Alert", "Update", "Support"].map((type) => (
            <button
              key={type}
              className={`tab-button ${newSelectedOptionContent === type ? "active" : ""}`}
              onClick={() => setNewSelectedOptionContent(type)}
            >
              {type}
            </button>
          ))}
        </div>
        <form onSubmit={handleAddTextContent}>
          <RichTextEditor
            value={headingEditor}
            onChange={setHeadingEditor}
            placeholder="Enter notification title..."
          />
          <br />
          <RichTextEditor
            value={detailsEditor}
            onChange={setDetailsEditor}
            placeholder="Enter notification details..."
          />
          <br />
          <div className="notification-date-picker">
            <select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              required
            >
              <option value="" disabled>Select Role</option>
              <option value="all">All</option>
              {rolesDocument &&
                rolesDocument.roles.map((role, index) => (
                  <option key={index} value={role} style={{ textTransform: "capitalize" }}>
                    {role}
                  </option>
                ))}
            </select>
            <input
              type="date"
              min={tomorrow.toISOString().split("T")[0]}
              value={notificationDate.toISOString().substr(0, 10)}
              onChange={(e) => setNotificationDate(new Date(e.target.value))}
              required
            />
          </div>
          <div className="d-flex justify-content-between">
            <button type="button" className="btn_border" onClick={onClose}>Cancel</button>
            <button type="submit" className="btn_fill">Save</button>
          </div>
        </form>
      </div>
    </div>

  );
};

export default AddNotification;

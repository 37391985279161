import React, { useState } from "react";
import "./AddNewDynamicPage.scss";

// Import Firebase hooks
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useCollection } from "../../../firebase files/hooks/useCollection";
const AddNewDynamicPage = () => {
  const [popupVisible, setPopupVisible] = useState(false); // Track popup visibility
  const [pageName, setPageName] = useState(""); // Track the new page name input
  const [errorMessage, setErrorMessage] = useState(""); // Track validation errors
  const [successMessage, setSuccessMessage] = useState(""); // Track success message
  const [copiedId, setCopiedId] = useState(null); // Track the copied ID

  const { addDocument, response } = useFirestore("pages-data"); // Firestore hook for adding a document

  // Fetch all pages from Firestore
  const { documents: allPages, error: allPagesError } = useCollection('pages-data');

  // console.log("document",addedPages);


  // Handle adding a new page
  const handleAddPage = async () => {
    if (!pageName.trim()) {
      setErrorMessage("Page name is required.");
      return;
    }

    try {
      const newPageData = {
        pageName: pageName.trim(),
        pageTitle: "", // Initialize with a default value or another input field
        pageContent: "", // Initial content for the new page
        createdAt: new Date(), // Optional: add a timestamp
      };

      await addDocument(newPageData);

      if (response.error) {
        console.error("Error adding document:", response.error);
        setErrorMessage("An error occurred while adding the page.");
      } else {
        console.log("Page added successfully.");
        setPopupVisible(false);
        setPageName(""); // Reset input field
        setErrorMessage("");
        setSuccessMessage(`Page "${newPageData.pageName}" added successfully.`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="add-new-dynamic-page">
      {/* Add Page Button */}
      <div className="menu-and-submenu-table-container">
        <div className="section-title d-flex justify-content-between align-items-center">
          <p>Add New Page:</p>
          <button
          className="btn_fill"
          onClick={() => setPopupVisible(true)}
        >
          Add New Page
        </button>
        </div>
        <br />
        <div className="all-dynamic-pages-list-div">
          {allPages &&
            allPages.map((doc) => (
              <div key={doc.id} className="all-dynamic-pages-list">
                <p>
                  <div className="d-flex"><strong style={{ color: "var(--black-color)" }}>Page Name:</strong> &nbsp;<p style={{ color: "var(--grey-dark)" }}>{doc && doc.pageName || "Untitled"}</p></div>
                  <div className="d-flex"> <strong style={{ color: "var(--black-color)" }}>ID (Page Url):</strong> &nbsp;<p style={{ color: "var(--grey-dark)" }}>{doc.id} </p>&nbsp;<span
                    className="copy-id-btn"
                    onClick={() => {
                      navigator.clipboard.writeText(doc.id);
                      setCopiedId(doc.id);
                      setTimeout(() => setCopiedId(null), 4000); // Reset after 2 seconds
                    }}
                    style={{ cursor: "pointer", color: "blue", fontSize: "10px" }}
                  >
                    {copiedId === doc.id ? "COPIED" : "COPY"}
                  </span>
                  </div>
                </p>
              </div>
            ))}
        </div>
        <br />
      
        {successMessage && (
          <p style={{ color: "green", marginTop: "10px", fontSize: "14px" }}>
            {successMessage}
          </p>
        )}
        <br />
        <p style={{ fontSize: "13px" }}>
          <span style={{ fontWeight: "bold", color: "var(--red-color)" }}>
            Note:
          </span>
          &nbsp;Page Settings: You can add a new dynamic page by clicking on the button.
        </p>
      </div>

      {/* Popup for adding a new page */}
      {popupVisible && (
        <div className="add-new-dynamic-page-popup-overlay">
          <div className="add-new-dynamic-page-popup-container">
            <div className="add-new-dynamic-page-popup-header">
              <div>
                <h5>Add New Page</h5>
              </div>
              <span
                className="material-symbols-outlined add-new-dynamic-page-popup-close"
                onClick={() => setPopupVisible(false)}
              >
                close
              </span>
            </div>
            <div className="add-new-dynamic-page-popup-body">
              <div className="add-new-dynamic-page-popup-field">
                <label>Page Name:</label>
                <input
                  type="text"
                  value={pageName}
                  onChange={(e) => setPageName(e.target.value)}
                  className="add-new-dynamic-page-popup-input"
                  required
                  placeholder="Enter page name"
                />
                {errorMessage && (
                  <p style={{ color: "red", fontSize: "12px" }}>{errorMessage}</p>
                )}
              </div>
            </div>
            <div className="add-new-dynamic-page-popup-footer">
              <button
                className="btn_fill"
                onClick={handleAddPage}
              >
                Add Page
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewDynamicPage;

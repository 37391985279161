import React, { useState, useEffect, useMemo } from "react";
import UserList from "../../default components/UserList"
import { useLocation } from "react-router-dom";
import {useCollection} from "../../firebase files/hooks/useCollection"

const PGAdminUser = () => {
  const location = useLocation();

  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { documents: allUsers, error } = useCollection("users", null, [
    "createdAt",
    "desc",
  ]);
  const [searchValue, setSearchValue] = useState("");
  const [filterOption, setFilterOption] = useState("all");
  const [activeFilter, setActiveFilter] = useState("all");

  const filteredUsers = useMemo(() => {
    return allUsers?.filter(
      (user) =>
        user.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
        user.phoneNumber.includes(searchValue) ||
        user.email.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [allUsers, searchValue]);

  const filteredUsersByTime = useMemo(() => {
    return filteredUsers?.filter((user) => {
      const createdAt = user.createdAt.toDate();
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      switch (filterOption) {
        case "last3months":
          return createdAt >= new Date(currentYear, currentMonth - 2, 1);
        case "last6months":
          return createdAt >= new Date(currentYear, currentMonth - 5, 1);
        case "year2023":
          return (
            createdAt >= new Date(2023, 0, 1) &&
            createdAt < new Date(2024, 0, 1)
          );
        case "year2024":
          return (
            createdAt >= new Date(2024, 0, 1) &&
            createdAt < new Date(2025, 0, 1)
          );
        default:
          return true;
      }
    });
  }, [filteredUsers, filterOption]);

  const filteredUsersByType = useMemo(() => {
    return filteredUsersByTime?.filter((user) => {
      switch (activeFilter) {
        case "active":
          return user.status === "active";
        case "inactive":
          return user.status === "inactive";
        case "customer":
          return user.role === "customer";
        case "admin":
          return user.role === "admin";
        case "all":
        default:
          return true;
      }
    });
  }, [filteredUsersByTime, activeFilter]);

  const handleFilterClick = (filter) => {
    setActiveFilter((prev) => (prev === filter ? "all" : filter));
  };

  const getUsersCountByType = (type) => {
    if (!filteredUsersByTime) return 0;
    switch (type) {
      case "total":
        return filteredUsersByTime.length;
      case "active":
        return filteredUsersByTime.filter((user) => user.status === "active")
          .length;
      case "inactive":
        return filteredUsersByTime.filter((user) => user.status === "inactive")
          .length;
      case "customer":
        return filteredUsersByTime.filter((user) => user.role === "customer")
          .length;
      case "admin":
        return filteredUsersByTime.filter((user) => user.role === "admin")
          .length;
      default:
        return 0;
    }
  };

  return (
    <div>
      <div className="user_list_filter_search">
        <div className="search_bar">
          <input
            type="text"
            placeholder="Search User"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="user_list_filter_dropdown yearly">
          <select
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
            className="pc_search_y_year"
          >
            <option value="all">All</option>
            <option value="last3months">Last 3 Months</option>
            <option value="last6months">Last 6 Months</option>
            <option value="year2023">2023</option>
            <option value="year2024">2024</option>
          </select>
        </div>
      </div>

      <div className="user_numbers-stats">
        <p
          className="user_numbers-total d-flex align-items-center"
          style={{ cursor: "pointer", color:"green" }}
          onClick={() => handleFilterClick("all")}
        >
          Total Users: {getUsersCountByType("total")}
          &nbsp;
          {getUsersCountByType("total") > 0 && (
            <span className="material-symbols-outlined">
              {activeFilter === "all" ? "arrow_downward" : "arrow_upward "}
            </span>
          )}
        </p>
        <p
          className="user_numbers-customers d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleFilterClick("customer")}
        >
          Customers: {getUsersCountByType("customer")} &nbsp;
          {getUsersCountByType("customer") > 0 && (
            <span className="material-symbols-outlined">
              {activeFilter === "customer" ? "arrow_downward" : "arrow_upward "}
            </span>
          )}
        </p>
        <p
          className="user_numbers-admin d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleFilterClick("admin")}
        >
          Admin: {getUsersCountByType("admin")} &nbsp;
          {getUsersCountByType("admin") > 0 && (
            <span className="material-symbols-outlined">
              {activeFilter === "admin" ? "arrow_downward" : "arrow_upward "}
            </span>
          )}
        </p>
        <p
          className="user_numbers-active d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleFilterClick("active")}
        >
          Active: {getUsersCountByType("active")} &nbsp;
          {getUsersCountByType("active") > 0 && (
            <span className="material-symbols-outlined">
              {activeFilter === "active" ? "arrow_downward" : "arrow_upward "}
            </span>
          )}
        </p>
        <p
          className="user_numbers-inactive d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleFilterClick("inactive")}
        >
          Inactive: {getUsersCountByType("inactive")}
          &nbsp;
          {getUsersCountByType("inactive") > 0 && (
            <span className="material-symbols-outlined">
              {activeFilter === "inactive" ? "arrow_downward" : "arrow_upward "}
            </span>
          )}
        </p>
      </div>

      {error && <p className="error">{error}</p>}
      <div className="userlist">
        <div className="container-fluid">
          <div className="user_card">
            {filteredUsersByType &&
              filteredUsersByType.map((user) => (
                <UserList key={user.uid} user={user} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PGAdminUser;

import React, { useState, useRef } from "react";
import "./LoginImageSetting.scss";
import { useLocation } from "react-router-dom";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { projectStorage } from "../../firebase config/config";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import Popup from "../../default components/Popup";

const LoginImageSetting = () => {
    const location = useLocation();

    const { updateDocument: updateLoginPageDocument } = useFirestore("pages-data");
    const { document: loginPageImages } = useDocument("pages-data", "loginSignUp");

    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [selectedImageToDelete, setSelectedImageToDelete] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);

    const fileInputRef = useRef(null);

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedImage(URL.createObjectURL(e.target.files[0]));
            setImageFile(e.target.files[0]);
        }
    };

    const handleConfirm = async () => {
        if (!imageFile) return;
        setIsUploading(true);
        setUploadError(null);

        const storageRef = projectStorage.ref();
        const fileRef = storageRef.child(`loginSignUp/Images/${imageFile.name}`);

        try {
            await fileRef.put(imageFile);
            const downloadURL = await fileRef.getDownloadURL();

            const currentImages = loginPageImages?.images || [];
            await updateLoginPageDocument("loginSignUp", {
                images: [...currentImages, downloadURL],
            });
        } catch (error) {
            setUploadError("Failed to upload image. Please try again.");
        } finally {
            setIsUploading(false);
            setSelectedImage(null);
        }
    };

    const handleDeleteImagePopup = (imgUrl) => {
        setSelectedImageToDelete(imgUrl);
        setIsDeletePopupOpen(true);
    };

    const handleDeleteImage = async () => {
        if (!selectedImageToDelete) return;
        try {
            const updatedImages = loginPageImages?.images.filter((img) => img !== selectedImageToDelete);
            await updateLoginPageDocument("loginSignUp", { images: updatedImages });

            const fileRef = projectStorage.refFromURL(selectedImageToDelete);
            await fileRef.delete();

            setSelectedImageToDelete(null);
        } catch (error) {
            console.error("Error deleting image: ", error);
        }
    };

    return (
        <div className="container pg_login_signup_parent_div">
             <div className="section-title">
              <p>Login Page Setting</p>
              <h2>Change Images of Login/Signup Page</h2>
              <br/>
            </div>
            <Popup
                showPopupFlag={isDeletePopupOpen}
                setShowPopupFlag={setIsDeletePopupOpen}
                msg="Are you sure you want to delete this banner?"
                setPopupReturn={(confirmed) => {
                    if (confirmed) {
                        handleDeleteImage();
                    }
                }}
            />

            <div className="container">
                <div className="row">
                    <div className="pg_login_signup_images-grid">
                        <div className="col-6 col-lg-3 col-md-4 pg_login_signup_popup-content add-image-card">
                            <div>
                                <div
                                    className={`pg_login_signup_selected-image-preview ${isUploading ? "dull-background" : ""}`}
                                >
                                    {selectedImage ? <><img
                                        src={selectedImage}
                                        alt="Selected"
                                        className="pg_login_signup_default_img"
                                    /></> : <><span class="material-symbols-outlined">
                                        add_photo_alternate
                                    </span></>}


                                    {isUploading && (
                                        <div className="pg_login_signup_uploading-gif-container">
                                            <img
                                                src="/assets/img/cloud-upload.gif"
                                                alt="Uploading"
                                                className="pg_login_signup_section-uploading-gif"
                                            />
                                        </div>
                                    )}
                                </div>

                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                />

                                <div className="d-flex justify-content-between align-item-center mt-4">
                                    <div className="d-flex align-items-center justify-content-center w-100">
                                        {!isUploading && (
                                            selectedImage ? (
                                                <span
                                                    className="material-symbols-outlined"
                                                    style={{ zIndex: "999999" }}
                                                    onClick={() => fileInputRef.current.click()}
                                                >
                                                    change_circle
                                                </span>
                                            ) : (
                                                <div>
                                                    <button
                                                    className="btn_border"
                                                    style={{ zIndex: "999999" }}
                                                    onClick={() => fileInputRef.current.click()}
                                                >
                                                    Replace
                                                </button>
                                                </div>
                                            )
                                        )}

                                    </div>
                                    <div>
                                        {selectedImage &&
                                            <button
                                                className="btn_fill"
                                                onClick={handleConfirm}
                                                disabled={isUploading}
                                                style={{ zIndex: "999999" }}
                                            >
                                                {isUploading ? "Uploading..." : "Confirm"}
                                            </button>
                                        }
                                    </div>
                                </div>
                                {uploadError && (
                                    <div className="upload-error">{uploadError}</div>
                                )}
                            </div>
                        </div>
                        {loginPageImages?.images?.map((img, index) => (
                            <div key={index} className="col-6 col-lg-3 col-md-4 pg_login_signup_popup-content">
                                <img
                                    src={img}
                                    alt={`Login Image ${index}`}
                                />
                                <span
                                    className="material-symbols-outlined pg_login_signup_delete-icon"
                                    onClick={() => handleDeleteImagePopup(img)}
                                >
                                    delete
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default LoginImageSetting;

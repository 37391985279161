import React from 'react'
import AddNewDynamicPage from './AddNewDynamicPage'
import HideUnhidePages from './HideUnhidePages'
const PageSettings = () => {
  return (
    <div className='container'>
      <div className="section-title">
        <p>Page Control</p>
        <h2>You Can Change Your Pages Setting</h2>
      </div>
      <HideUnhidePages />
      <AddNewDynamicPage />
      <br />
    </div>
  )
}

export default PageSettings

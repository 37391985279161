import React, { useState, useEffect } from "react";
import "./MyGridLayout.scss";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css"; // Required CSS
import "react-resizable/css/styles.css"; // Required for resizing
import RichTextEditor from "react-rte";

import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";

const ResponsiveGridLayout = WidthProvider(Responsive);

const generateUniqueId = (() => {
    let counter = 1; // Start counter
    return () => (counter++).toString(); // Return incremented ID
})();


const MyGridLayout = () => {
    const { user } = useAuthContext();

    const { updateDocument } = useFirestore("pages-data");
    const { document } = useDocument("pages-data", "new-grid-page");

    const [items, setItems] = useState([]);
    const [selectedLayout, setSelectedLayout] = useState("default");
    const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
    const [editingItemId, setEditingItemId] = useState(null);
    const [isDraggable, setIsDraggable] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);


    // Synchronize items when the document is updated
    useEffect(() => {
        if (document && document.items) {
            setItems(document.items);
            if (document.layout) {
                setSelectedLayout(document.layout);
            }
        }
    }, [document]);

    // Handle content changes (edit item content)
    const handleContentChange = (id, newContent) => {
        setItems((prev) =>
            prev.map((item) =>
                item.id === id ? { ...item, content: newContent } : item
            )
        );
        updateDocument("new-grid-page", { items });
    };

    // Handle editor changes
    const handleEditorChange = (value) => {
        setEditorValue(value);
    };

    // Save changes made through the editor
    const handleSaveClick = (id) => {
        const updatedContent = editorValue.toString("html");
        setItems((prev) =>
            prev.map((item) =>
                item.id === id ? { ...item, content: updatedContent } : item
            )
        );
        updateDocument("new-grid-page", { items });
        setEditingItemId(null);
    };

    // Render content based on item type
    const renderContent = (item) => {
        switch (item.type) {
            case "image":
                return (
                    <img
                        src={item.content}
                        alt={`Content ${item.id}`}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        onClick={() => {
                            const input = document.createElement("input");
                            input.type = "file";
                            input.accept = "image/*";
                            input.onchange = (e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    const reader = new FileReader();
                                    reader.onload = () => handleContentChange(item.id, reader.result);
                                    reader.readAsDataURL(file);
                                }
                            };
                            input.click();
                        }}
                    />
                );
            case "text":

                return user && user.role === "admin" ? (
                    <div style={{ padding: "10px", background: "white", cursor: "text" }}>
                        {editingItemId === item.id ? (
                            <div>
                                <RichTextEditor
                                    value={editorValue}
                                    onChange={handleEditorChange}
                                />
                                <button
                                    className="product_edit_save_btn"
                                    onClick={() => handleSaveClick(item.id)}
                                >
                                    Save
                                </button>
                            </div>
                        ) : (
                            <div
                                onClick={() => {
                                    setEditingItemId(item.id);
                                    setEditorValue(
                                        RichTextEditor.createValueFromString(
                                            item.content || "",
                                            "html"
                                        )
                                    );
                                }}
                                dangerouslySetInnerHTML={{ __html: item.content }}
                            />
                        )}
                    </div>
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                );

            case "video":
                return (
                    <video controls style={{ width: "100%", height: "100%" }}>
                        <source src={item.content} type="video/mp4" />
                    </video>
                );
            case "spacer":
                return (
                    <div>
                        <br />
                    </div>
                );
            default:
                return null;
        }
    };

    // Add new item with unique ID
    const addItem = (type) => {
        const totalCreatedElements = document?.totalCreatedElements || 0; // Fallback to 0 if undefined
        const newId = `${type}-${totalCreatedElements + 1}`;
            const newItem = {
            id: newId,
            type,
            content: type === "text" ? "Click to edit text" : "",
            x: 0,
            y: Infinity,
            w: 3,
            h: 3,
        };
        const updatedItems = [...items, newItem];
        setItems(updatedItems);
        updateDocument("new-grid-page", { items: updatedItems });

          // Update items and totalCreatedElements in Firestore
          updateDocument("new-grid-page", {
            items: updatedItems,
            totalCreatedElements: totalCreatedElements + 1, // Increment safely
        });

    };

    // Detect collisions and adjust positions of related elements
    const handleDragStop = (layout, oldItem, newItem) => {
        const updatedItems = items.map((item) => {
            if (item.id === newItem.i) {
                return { ...item, x: newItem.x, y: newItem.y };
            }

            // Detect collision
            const isColliding =
                item.x < newItem.x + newItem.w &&
                item.x + item.w > newItem.x &&
                item.y < newItem.y + newItem.h &&
                item.y + item.h > newItem.y;

            if (isColliding) {
                // Adjust the position of the colliding item
                const updatedX = item.x + newItem.w; // Move it to the right of the dragged item
                const updatedY = item.y; // Keep the same row (or adjust further if needed)

                return { ...item, x: updatedX, y: updatedY };
            }

            return item;
        });

        setItems(updatedItems);
        updateDocument("new-grid-page", { items: updatedItems });
    };


    // Handle resize stop event
    const handleResizeStop = (layout, oldItem, newItem) => {
        const updatedItems = items.map((item) => {
            if (item.id === newItem.i) {
                return { ...item, w: newItem.w, h: newItem.h };
            }
            return item;
        });
        setItems(updatedItems);
        updateDocument("new-grid-page", { items: updatedItems });
    };

    const enableDragging = () => {
        setIsDraggable(true); // Enable dragging when the icon is double-clicked
    };


    // DELETE ELEMENT
    // Handle element click to show the delete icon
    const handleElementClick = (id) => {
        setSelectedItemId(id);
    };

    // Handle delete icon click
    const handleDelete = (id) => {
        const updatedItems = items.filter((item) => item.id !== id);
        setItems(updatedItems);
        updateDocument("new-grid-page", { items: updatedItems });
        setSelectedItemId(null);
    };

    return (
        <div className="my-grid-layout-main-div">
            <div className="my-grid-layout-main-div-main-content">
                <ResponsiveGridLayout
                    className="my-grid-layout-main-div-layout"
                    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                    cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                    rowHeight={50}
                    isDraggable={true} // Enable dragging globally
                    isResizable={true}
                    onResizeStop={handleResizeStop}
                    onDragStop={handleDragStop}
                    draggableHandle=".drag-icon"
                >
                    {items.map((item) => (
                        <div
                            key={item.id}
                            data-grid={{
                                x: item.x || 0,
                                y: item.y || 0,
                                w: item.w || 3,
                                h: item.h || 3,
                            }}
                            style={{
                                background: user && user.role === "admin" ? "var(--grey-light)" : "none",
                                overflow: "hidden",
                                position: "relative",
                                cursor: "pointer"
                            }}
                            className={user && user.role === "admin" ? "my-grid-layout-main-div-layout-hover" : "my-grid-layout-main-div-layout-hover-none"}
                            onClick={() => handleElementClick(item.id)}
                        >
                            {user && user.role === "admin" && (
                                <span
                                    className="material-symbols-outlined drag-icon"
                                    onClick={enableDragging} // Enable dragging only via icon
                                    style={{ cursor: "move", zIndex: "99999" }}
                                >
                                    apps
                                </span>
                            )}
                            {renderContent(item)}
                            {user && user.role === "admin" && (
                                <>
                                    {selectedItemId === item.id && (
                                        <span class="material-symbols-outlined" onClick={() => handleDelete(item.id)}
                                            style={{
                                                position: "absolute",
                                                top: "5px",
                                                right: "5px",
                                                cursor: "pointer",
                                                zIndex: 10000,
                                            }}
                                        >
                                            delete
                                        </span>
                                    )}

                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: "5px",
                                            right: "5px",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            color: "white",
                                            padding: "2px 5px",
                                            borderRadius: "3px",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {`W: ${item.w || 1}, H: ${item.h || 1}`}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </ResponsiveGridLayout>
            </div>
            {user && user.role === "admin" && (
                <div className="sidebar right">
                    <h3>Select Layout</h3>
                    <div>
                        <button onClick={() => addItem("image")}>Add Image</button>
                        <button onClick={() => addItem("text")}>Add Text</button>
                        <button onClick={() => addItem("video")}>Add Video</button>
                        <button onClick={() => addItem("spacer")}>Add Spacer</button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default MyGridLayout;

import React from "react";
import "./CorporateTwoFooter.scss";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useDocument } from "../../../../../firebase files/hooks/useDocument";
import { useAuthContext } from "../../../../../firebase files/hooks/useAuthContext";

const CorporateTwoFooter = () => {
  const { user } = useAuthContext();
  const { document: dbsocialmedialinks, error: dbsocialmediaError } =
    useDocument("settings", "socialmedia");

  const { document: contactDetails, error: contactDetailserror } = useDocument(
    "settings",
    "contactUs"
  );

  const { document, seterror } = useDocument("settings", "organisation");

  const { document: dbTemplatesPaletteDocuments, error: dbTemplatesPaletteError } =
    useDocument("settings", "themePalette");

  return (
    <div className="corporateTwo-footer"
      style={{
        background: dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "diwaliThemePalette"
          ? "linear-gradient(to right, #F57000, #F5BF00)"
          : dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "defaultThemePalette"
            ? "lavender"
            : dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "holiThemePalette"
              ? `radial-gradient(circle at 10% 20%, rgba(255, 0, 0, 1), transparent 20%),
         radial-gradient(circle at 80% 30%, rgba(0, 255, 0, 1), transparent 20%),
         radial-gradient(circle at 40% 70%, rgba(0, 0, 255, 1), transparent 20%),
         radial-gradient(circle at 90% 80%, rgba(255, 255, 0, 1), transparent 20%),
         radial-gradient(circle at 30% 90%, rgba(255, 165, 0, 1), transparent 20%),
         radial-gradient(circle at 20% 50%, rgba(255, 0, 255, 1), transparent 15%),
         radial-gradient(circle at 70% 40%, rgba(0, 255, 255, 1), transparent 25%),
         radial-gradient(circle at 50% 50%, rgba(255, 192, 203, 1), transparent 30%),
         radial-gradient(circle at 60% 60%, rgba(173, 216, 230, 1), transparent 25%),
         radial-gradient(circle at 85% 20%, rgba(255, 223, 186, 1), transparent 20%)`
              : null
      }}
    >
      <div className="corporateTwo-footer-upper">
        <div className="corporateTwo-footer-upper-left">
          <div className="corporateTwo-footer-upper-left-title">
            <h2>Smart Wash</h2>
            <p>
              Smart wash is world class laundry and drycleaning service in pune
              which provides all types of drycleaning and laundry services in
              different types of sector .
            </p>
          </div>

          <div className="corporateTwo-footer-upper-left-iso">
            <h5>ISO 9001:2015</h5>
            <h5>ISO 14001:2015</h5>
            <h5>ISO 45001:2018</h5>
            <h5>EN 14065:2016</h5>
          </div>

          <div className="corporateTwo-footer-upper-left-socials">
            <a
              href={
                dbsocialmedialinks && dbsocialmedialinks.socialmedia.facebook
              }
              target="__blank"
            >
              <FaFacebookSquare size={30} />
            </a>

            <a
              href={
                dbsocialmedialinks && dbsocialmedialinks.socialmedia.instagram
              }
              target="__blank"
            >
              <FaInstagram size={30} />
            </a>

            <a
              href={
                dbsocialmedialinks && dbsocialmedialinks.socialmedia.twitter
              }
              target="__blank"
            >
              <FaSquareXTwitter size={30} />
            </a>

            <a
              href={
                dbsocialmedialinks && dbsocialmedialinks.socialmedia.youtube
              }
              target="__blank"
            >
              <FaYoutube size={30} />
            </a>

            <a
              href={
                dbsocialmedialinks && dbsocialmedialinks.socialmedia.linkedin
              }
              target="__blank"
            >
              <FaLinkedin size={30} />
            </a>
          </div>
        </div>

        <div className="corporateTwo-footer-upper-middle">
          <h3>CONTACT INFO</h3>

          <div className="corporateTwo-footer-contact-info">
            <div className="corporateTwo-footer-contact-item-cm">
              <a
                href={"tel:" + (contactDetails && contactDetails.contactNumber)}
                style={{ color: "var(--black-color)" }}
              >
                <span>
                  <FaPhoneAlt />
                </span>
                <span className="corporateTwo-footer-contact-item-info">{contactDetails && contactDetails.contactNumber}</span>
              </a>
            </div>

            <div className="corporateTwo-footer-contact-item-cm">
              <a>
                <span>
                  <MdEmail />
                </span>
                <span className="corporateTwo-footer-contact-item-info">{contactDetails && contactDetails.contactEmail}</span>
              </a>
            </div>

            <div className="corporateTwo-footer-contact-item-cm">
              <a>
                <span>
                  <FaLocationDot />
                </span>
                <span className="corporateTwo-footer-contact-item-info">{document && document.address}</span>
              </a>
            </div>
          </div>
        </div>

        <div className="corporateTwo-footer-upper-right">
          <h3>Quick Links</h3>

          <div className="corporateTwo-footer-upper-right-links">
            <Link to="/faq">
              <span>FAQ</span>
            </Link>
            <Link to="/blog-articles">
              <span>Blogs</span>
            </Link>
            <Link to="/aboutus">
              <span>About</span>
            </Link>
            <Link to={"/contact"}>
              <span>Contact Us</span>
            </Link>
          </div>
        </div>

        <div className="corporateTwo-footer-upper-right">
          <h3>More</h3>

          <div className="corporateTwo-footer-upper-right-links">
            <Link to="/gallery">
              <span>Gallery</span>
            </Link>
            <Link to="/products">
              <span>Services</span>
            </Link>
            <Link to="/privacy-policy">
              <span>Privacy Policy</span>
            </Link>

            <Link to={"/term-condition"}>
              <span>Terms and Conditions</span>
            </Link>
          </div>
        </div>
      </div>

      <hr className="corporateTwo-footer-divider" />

      <div className="corporateTwo-footer-bottom">
        <div className="corporateTwo-footer-bottom-left">
          <h3>&copy; 2024 Smart Wash All Rights Reserved.</h3>
        </div>

        <div className="corporateTwo-footer-bottom-right">
            <a href="https://www.hyperclouddigital.com" target="__blank">
              {document && document.copyRightArea ? (
                document.copyRightArea.trim() !== "" ? (
                  <>{document.copyRightArea}</>
                ) : (
                  <>
                    Design & Developed by HyperCloud Digital Solutions
                  </>
                )
              ) : (
                <>
                  Design & Developed by HyperCloud Digital Solutions
                </>
              )}
            </a>
        </div>
      </div>
    </div>
  );
};

export default CorporateTwoFooter;

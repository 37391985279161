import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import RichTextEditor from "react-rte";

// Import custom Firestore hooks
import { useDocument } from "../firebase files/hooks/useDocument";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";

const DynamicPages = () => {
    const { id } = useParams(); // Fetch document ID from URL
    const location = useLocation();

    const { user } = useAuthContext();
    const { updateDocument, response } = useFirestore("pages-data");
    const { document, error } = useDocument("pages-data", id); // Fetch document from "pages-data" collection

    const [value, setValue] = useState(RichTextEditor.createEmptyValue());
    const [hasChanges, setHasChanges] = useState(false);

    // Scroll to the top of the page whenever the location changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    // Load the data into the editor when the document is fetched
    useEffect(() => {
        if (document) {
            setValue(RichTextEditor.createValueFromString(document.pageContent, "html"));
        }
    }, [document]);

    // Handle changes in the editor
    const handleEditorChange = (newValue) => {
        setValue(newValue);

        // Track changes by comparing current editor value with original content
        setHasChanges(newValue.toString("html") !== document.pageContent);
    };

    // Save changes to Firestore
    const handleSaveClick = async () => {
        try {
            const content = value.toString("html");

            // Update the document in Firestore
            await updateDocument(id, { pageContent: content });

            if (response.error) {
                console.error("Error updating document:", response.error);
            } else {
                console.log("Document updated successfully");
                setHasChanges(false);
            }
        } catch (error) {
            console.error("An error occurred while updating the document:", error);
        }
    };

    if (error) return <div></div>;
    if (!document) return <div>Loading...</div>;

    return (
        <div>
            <div className="top_header_pg pa_bg">
                <div className="pa_inner_page info_in_ul_li max-991">
                    <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
                        <h2>{document.pageName}</h2>
                        <p>{document.pageTitle}</p>
                    </div>

                    <div className="corporateTwo-policy-body">
                        {user && user.role === "admin" && (
                            <div>
                                <RichTextEditor value={value} onChange={handleEditorChange} />
                                {hasChanges && (
                                    <button className="product_edit_save_btn" onClick={handleSaveClick}>
                                        Save
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="container">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: document &&
                                    document.pageContent.toString("html"),
                            }}
                        ></p>
                    </div>
                </div>
            </div>
            <br />
        </div>
    );
};

export default DynamicPages;

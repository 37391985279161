import { useState, useEffect } from 'react';
import { projectFirestore, timestamp } from '../../firebase config/config';
import { useAuthContext } from './useAuthContext';
import { useFirestore } from './useFirestore';

const useCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const { user } = useAuthContext();
  const { addDocument, updateDocument, deleteDocument } = useFirestore('cart');

  useEffect(() => {
    if (user) {
      const unsubscribe = projectFirestore
        .collection('cart')
        .where('userId', '==', user.uid)
        .onSnapshot((snapshot) => {
          const items = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setCartItems(items);
        });

      return () => unsubscribe();
    }
  }, [user]);

  const addToCart = async (productId) => {
    const productDoc = await projectFirestore.collection('products').doc(productId).get();
    const product = productDoc.data();
    
    if (!product || product.stock <= 0) {
      throw new Error(`${product.name} is currently out of stock`);
    }

    const minQuantity = product.minQuantity || 1;
    const existingItem = cartItems.find(item => item.productId === productId);

    if (existingItem) {
      if (existingItem.quantity + 1 > product.stock) {
        throw new Error(`Only ${product.stock} units available for ${product.name}`);
      }
      if (product.maxQuantity && existingItem.quantity + 1 > product.maxQuantity) {
        throw new Error(`Maximum quantity allowed for ${product.name} is ${product.maxQuantity}`);
      }
      
      await updateDocument(existingItem.id, {
        quantity: existingItem.quantity + 1,
        updatedAt: timestamp.fromDate(new Date())
      });
    } else {
      await addDocument({
        userId: user.uid,
        productId,
        quantity: minQuantity,
        createdAt: timestamp.fromDate(new Date()),
        updatedAt: timestamp.fromDate(new Date())
      });
    }
  };

  const addToCartFromDetails = async (productId, size, color, variantName, discountedPrice) => {
    const existingItem = cartItems.find(item => item.productId === productId && item.size === size && item.color.colorName === color.colorName && item.variantName === variantName && item.discountedPrice === discountedPrice);

    if (existingItem) {
      await updateDocument(existingItem.id, {
        quantity: existingItem.quantity + 1,
        updatedAt: timestamp.fromDate(new Date())
      });
    } else {
      await addDocument({
        userId: user.uid,
        productId,
        size,
        color,
        variantName,
        discountedPrice,
        quantity: 1,
        createdAt: timestamp.fromDate(new Date()),
        updatedAt: timestamp.fromDate(new Date())
      });
    }
  };

  const updateCartItemQuantity = async (cartItemId, newQuantity) => {
    const cartItem = cartItems.find(item => item.id === cartItemId);
    if (!cartItem) return;

    const productDoc = await projectFirestore.collection('products').doc(cartItem.productId).get();
    const product = productDoc.data();

    if (!product) return;

    // If quantity is 0, remove the item regardless of min quantity
    if (newQuantity === 0) {
        await deleteDocument(cartItemId);
        return;
    }

    const minQuantity = product.minQuantity || 1;
    const maxQuantity = product.maxQuantity || product.stock;

    // Only check min/max constraints if we're not removing the item
    if (newQuantity > 0) {
        if (newQuantity < minQuantity) {
            throw new Error(`Minimum quantity required for ${product.name} is ${minQuantity}`);
        }

        if (newQuantity > maxQuantity) {
            throw new Error(`Maximum quantity allowed for ${product.name} is ${maxQuantity}`);
        }

        if (newQuantity > product.stock) {
            throw new Error(`Only ${product.stock} units available for ${product.name}`);
        }

        await updateDocument(cartItemId, {
            quantity: newQuantity,
            updatedAt: timestamp.fromDate(new Date())
        });
    }
};

  const removeFromCart = async (cartItemId) => {
    await deleteDocument(cartItemId);
  };

  const clearCart = async () => {
    cartItems.forEach(async (item) => {
      await deleteDocument(item.id);
    });
  };

  return {
    cartItems,
    addToCart,
    updateCartItemQuantity,
    removeFromCart,
    addToCartFromDetails,
    clearCart
  };
};

export default useCart;
import React from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./Ads.css";
import { useDocument } from "../firebase files/hooks/useDocument";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Adcarousel = () => {
  const { document: dbLoginImageDocuments, error: dbLoginImageError } =
    useDocument("pages-data", "loginSignUp");

  // Default images
  const defaultImages = [
    "./assets/img/ad1.jpg",
    "./assets/img/ad2.jpg",
    "./assets/img/ad3.jpg",
    "./assets/img/ad4.jpg",
    "./assets/img/ad5.jpg",
    "./assets/img/ad6.jpg",
  ];

  // Use images from Firestore or fallback to default images
  const imagesToShow =
    dbLoginImageDocuments?.images && dbLoginImageDocuments.images.length > 0
      ? dbLoginImageDocuments.images
      : defaultImages;

  return (
    <div className="swipercomp">
      <div className="swipercomp_inner">
        <Swiper
          navigation
          pagination={{ clickable: true }}
          spaceBetween={30}
          slidesPerView={1}
          autoplay={{ delay: 3000 }} // Autoplay configuration
          loop // Enable looping behavior
        >
          {imagesToShow.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="ad_container">
                <img src={image} alt={`Ad ${index + 1}`} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Adcarousel;

import React, { useEffect, useRef, useState } from "react";
import "./BusinessThreeServices.scss";

const BusinessThreeServices = ({ id }) => {
  const headingRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [slideClass, setSlideClass] = useState("slide-in");

  const icons = ["room_service", "wifi", "vacuum", "directions_car", "local_dining"];

  const services = [
    "24/7 Room Service",
    "Complimentary Wi-Fi",
    "Daily Housekeeping",
    "Airport Pickup and Drop-off",
    "In-Room Dining",
  ];

  const serviceContents = [
    {
      heading: "Round-the-Clock Assistance for Your Every Need",
      description:
        "Our 24/7 room service ensures your comfort at any hour. Whether it’s a late-night snack or an urgent request, our dedicated staff is always ready to assist. Enjoy seamless service that caters to your needs, allowing you to relax and make the most of your stay.",
      image:
        "https://qloapps.com/wp-content/uploads/2022/07/pexels-cottonbro-6466283-scaled.jpg",
      alt: "24/7 Room Service",
    },
    {
      heading: "Seamless Connectivity Anytime, Anywhere in Hotel",
      description:
        "Stay connected with our high-speed complimentary Wi-Fi available throughout the property. Whether for work or leisure, you can enjoy uninterrupted internet access, stream your favorite shows, or attend virtual meetings with ease, making your stay productive and enjoyable.",
      image:
        "https://evolvesolutionsinc.com/wp-content/uploads/2020/11/AdobeStock_248319517-scaled.jpeg",
      alt: "wifi img",
    },
    {
      heading: "Fresh, Clean Spaces for a Relaxing Stay",
      description:
        "Our daily housekeeping service keeps your room spotless and inviting. From fresh linens to meticulous cleaning, we ensure your space is tidy and comfortable. Let us take care of the details while you focus on enjoying your stay.",
      image:
        "https://kocak-service.de/wp-content/uploads/2020/12/AdobeStock_236574440-1024x683.jpg",
      alt: "houskeeping img",
    },
    {
      heading: "Convenient Transfers for Stress-Free Travel Experience",
      description:
        "Travel hassle-free with our reliable airport pickup and drop-off service. Our professional drivers ensure a smooth journey between the hotel and airport, saving you time and effort. Start and end your trip stress-free with this convenient service.",
      image:
        "https://www.dehraduntaxiservice.in/images/airport.jpg",
      alt: "car img",
    },
    {
      heading: "Delicious Meals Delivered Right to Your Room",
      description:
        "Experience gourmet dining in the comfort of your room with our in-room dining service. Choose from an extensive menu of delicious options, freshly prepared and delivered promptly. Whether it’s breakfast in bed or a relaxing dinner, we bring the restaurant to you.",
      image:
        "https://i.pinimg.com/originals/7c/10/23/7c10232dc44ea45195c4d44bc609b9e3.jpg",
      alt: "in room dining img",
    },
  ];

  const handleTabClick = (index) => {
    if (index !== activeTab) {
      setSlideClass("slide-out");
      setTimeout(() => {
        setActiveTab(index);
        setSlideClass("slide-in");
      }, 500); // Matches the duration of the transition
    }
  };

  // CODE START FOR ANIMATION HEADING
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);
  // CODE END FOR ANIMATION HEADING

  return (
    <>
      <div className="large_padding_div" id={id}></div>
      <div className="container business-three-box-container">
        <div ref={headingRef} className="business-three-heading-div">
          <h3>
            <span>Your Comfort,</span> Our Top Priority
          </h3>
        </div>
        <div className="business-three-template-view-more-icon">
          <span className="material-symbols-outlined">arrow_forward_ios</span>{" "}
        </div>
      </div>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between business-three-services-top-selector">
          {services.map((service, index) => (
            <div
              key={index}
              onClick={() => handleTabClick(index)}
              className={`business-three-service-tab ${
                activeTab === index ? "active" : ""
              }`}
            >
              <span className="material-symbols-outlined">{icons[index]}</span>
              &nbsp;
              {service}
            </div>
          ))}
        </div>
        <br />
        <div className="business-three-service-selected-service">
          <div
            className={`business-three-service-selected-service-content ${slideClass}`}
          >
            <div className="row d-flex align-items-center">
              <div className="col-12 col-md-6">
                <h4 style={{ fontSize:"1.8rem", fontWeight:"700", color:"var(--black-color)" }}>
                  {serviceContents[activeTab].heading}
                </h4>
                <br />
                <p>{serviceContents[activeTab].description}</p>
                <br />
                <div>
                  <button className="btn_border">{services[activeTab]}</button>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-end">
                <img
                  src={serviceContents[activeTab].image}
                  alt={serviceContents[activeTab].alt}
                  className="business-three-service-selected-service-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="business-three-dots-container">
          {services.map((_, index) => (
            <div
              key={index}
              className={`business-three-dot ${
                activeTab === index ? "active" : ""
              }`}
            ></div>
          ))}
        </div>
      </div>
      <div className="large_padding_div"></div>
    </>
  );
};

export default BusinessThreeServices;

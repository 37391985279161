import * as React from 'react';
import './AccommodationCheckout.scss';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const AccommodationCheckout = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [guestForms, setGuestForms] = useState([0]); // Initial state with one guest form

  // Function to handle adding a new guest form
  const addGuestForm = () => {
    setGuestForms([...guestForms, guestForms.length]);
  };

  // Function to handle removing a guest form
  const removeGuestForm = (index: number) => {
    setGuestForms(guestForms.filter((_, i) => i !== index));
  };

  const [selectedCoupon, setSelectedCoupon] = useState('MMTHOMESTAYS');

  const coupons = [
    {
      id: 'MMTHOMESTAYS',
      name: 'MMTHOMESTAYS...',
      discount: '₹ 30,532',
      description: 'Congratulations! Discount of INR 30,532 Applied',
    },
    {
      id: 'MMTICICIFEST',
      name: 'MMTICICIFEST',
      discount: '₹ 18,820',
      description: 'Exclusive Offer on ICICI Credit Cards. Get INR 18,820 Off',
    },
    {
      id: 'ICICIRUPAY',
      name: 'ICICIRUPAY',
      discount: '₹ 19,941',
      description: 'Exclusive Offer on ICICI Cards EMI Option. Get INR 19,941 Off',
    },
    {
      id: 'MMTAUFEST',
      name: 'MMTAUFEST',
      discount: '₹ 16,775',
      description: 'Exclusive Offer on AU Small Finance Bank Cards. Get INR 16,775 Off',
    },
    {
      id: 'MMTHSBCEMI',
      name: 'MMTHSBCEMI',
      discount: '₹ 16,775',
      description: 'Interest Free EMI Offer on HSBC Credit Card. Get INR 16,775 Off',
    },
  ];

  const handleCouponSelect = (id) => {
    setSelectedCoupon(id);
  };

  return (
    <div className="container accommodation-property-checkout-container">
      <div className="accommodation-property-checkout-booking-review">
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h5>Booking Review</h5>
          </div>
          <div className='d-flex align-items-center gap-2'>
            <div style={{ backgroundColor: "red", borderRadius: "20px", padding: "4px 20px", fontWeight: "700", color: "white" }}>Details</div>
            &nbsp;
            <div>Payment Details</div>
          </div>
        </div>
      </div>

      <div className="accommodation-property-checkout-transport-info">
        <h5>House Of Bangalore</h5>
        <p className="accommodation-property-checkout-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit...
        </p>
        <div className="accommodation-property-checkout-transport-details">
          <div className="accommodation-property-checkout-detail-item">
            <span>Check-In</span>
            <strong>Nov 1 2024</strong>
          </div>
          <div className="accommodation-property-checkout-detail-item">
            <span>Check-Out</span>
            <strong>Nov 13 2024</strong>
          </div>
          <div className="accommodation-property-checkout-detail-item">
            <span>Rating</span>
            <strong>⭐⭐⭐⭐⭐</strong>
          </div>
        </div>
      </div>

      {/* CUSTOMER DETAILS CARD START */}
      {guestForms.map((_, index) => (
        <div className="accommodation-property-checkout-customer-details-card">
          <div className='d-flex justify-content-between align-items-center'>
            <h5>Member {index + 1} Details</h5>
            {index > 0 && (
              <div className='d-flex align-items-center justify-content-center' style={{ backgroundColor: " #dfdfdf", padding: "5px", borderRadius: "50%" }} onClick={() => removeGuestForm(index)}>
                <span class="material-symbols-outlined" style={{ fontSize: "18px" }}>
                  close
                </span>
              </div>)}
          </div>
          <form>
            <div className="accommodation-property-checkout-form-group">
              <label>Title</label>
              <select className="accommodation-property-checkout-form-control">
                <option>Mr</option>
                <option>Ms</option>
                <option>Mrs</option>
              </select>
            </div>

            <div className="accommodation-property-checkout-form-row">
              <div className="accommodation-property-checkout-form-group">
                <label>First Name</label>
                <input type="text" placeholder="Enter your first name" />
              </div>
              <div className="accommodation-property-checkout-form-group">
                <label>Last Name</label>
                <input type="text" placeholder="Enter your last name" />
              </div>
            </div>

            <div className="accommodation-property-checkout-form-group">
              <label>Email</label>
              <input type="email" placeholder="yourmail@gmail.com" />
            </div>

            <div className="accommodation-property-checkout-form-row">
              <div className="accommodation-property-checkout-form-group">
                <label>Country Code</label>
                <select className="accommodation-property-checkout-form-control">
                  <option>+91 (India)</option>
                </select>
              </div>
              <div className="accommodation-property-checkout-form-group">
                <label>Mobile</label>
                <input type="text" placeholder="+91 1234 3433" />
              </div>
            </div>
          </form>
          <div className='d-flex justify-content-between align-items-center'>
            <h6 style={{ cursor: 'pointer' }} onClick={addGuestForm}>
              + Add Member
            </h6>
          </div>
        </div>
      ))}
      {/* CUSTOMER DETAILS CARD END */}

      {/* PAYMENT CARD START */}
      <div className="row accommodation-property-checkout-payment-section">
        <div className='col-12 col-md-6'>
          <div className="coupon-code-container">
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <h3>Coupon Codes</h3>
              <h6 style={{ cursor: 'pointer' }}>
                View All
              </h6>
            </div>
            <div className="coupon-code-list">
              {coupons.map((coupon) => (
                <div key={coupon.id} className="coupon-option">
                  <input
                    type="radio"
                    id={coupon.id}
                    name="coupon"
                    checked={selectedCoupon === coupon.id}
                    onChange={() => handleCouponSelect(coupon.id)}
                  />
                  <label htmlFor={coupon.id}>
                    <div className="coupon-info">
                      <strong>{coupon.name}</strong>
                      <span className="coupon-discount">{coupon.discount}</span>
                    </div>
                    <p className="coupon-description">{coupon.description}</p>
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Price Breakup Card */}
          <div className="price-breakup-card">
            <h3 style={{ fontWeight: "700" }}>Price Breakup</h3>
            <div className="price-breakup-item">
              <span>Entire Apartment x 20 Nights</span>
              <span>₹ 2,46,780</span>
            </div>
            <p className="limited-time-sale">Limited Time Deal</p>
            <div className="price-breakup-item">
              <span>Total Discount</span>
              <span>₹ 1,57,007</span>
            </div>
            <div className="price-breakup-item">
              <span>Price after Discount</span>
              <span>₹ 89,773</span>
            </div>
            <div className="price-breakup-item">
              <span>Taxes & Service Fees</span>
              <span>₹ 24,648</span>
            </div>
            <div className="price-breakup-total">
              <span>Total Amount to be paid</span>
              <strong>₹ 1,14,421</strong>
            </div>
          </div>
        </div>

        <div className='col-12 col-md-6'>
          <Box className="accommodation-property-checkout-form">
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Pay Later" {...a11yProps(0)} />
                <Tab label="Pay Now" {...a11yProps(1)} />
              </Tabs>
            </AppBar>

            {/* Pay Now Tab (Existing Design) */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              <form className="">
                <div className="accommodation-property-checkout-payment-methods">
                  <button className="accommodation-property-checkout-payment-method active">CREDIT CARD</button>
                  <button className="accommodation-property-checkout-payment-method">WALLET</button>
                  <button className="accommodation-property-checkout-payment-method">UPI</button>
                </div>
                <button className="accommodation-property-checkout-confirm-btn">Confirm</button>
              </form>
            </TabPanel>

            {/* Pay Later Tab (Cash on Delivery Design) */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              <div className="cash-on-delivery-info" >
                <h3>Cash on Delivery</h3>
                <p>Once you confirm your order, payment will be collected upon delivery. Please ensure you have the exact amount ready.</p>
                <br />
                <Link to="/accommodation-dashboard">
                  <button className="accommodation-property-checkout-confirm-btn">Confirm</button>
                </Link>
              </div>
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AccommodationCheckout;

import React from "react";
import "./SecurityOneVideoSection.scss";

const SecurityOneVideoSection = () => {
    return (
        <div className="container security-one-video-section-parent">
            <div className="row">
                <div className="col-12 col-md-6 security-one-video-section-content">
                    <h2 className="security-one-video-section-heading">
                        <span class="material-symbols-outlined">
                            admin_panel_settings
                        </span>
                        &nbsp;Secure Entry
                    </h2>
                    <p className="security-one-video-section-description">
                        Streamline visitor access with ease. Ensure workplace safety and security
                        while maintaining compliance with your company's protocols.
                    </p>
                    <button className="btn_fill">
                        Try AccessiFy
                    </button>

                </div>
                <div className="col-12 col-md-6 security-one-video-section-video">
                    <iframe src="https://www.youtube.com/embed/AzoexMgp1gg?si=JhvHzLP1oUKSt09M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    );
};

export default SecurityOneVideoSection;

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './OrderSummary.scss';
import useOrders from '../firebase files/hooks/useOrders';
import { useDocument } from '../firebase files/hooks/useDocument';
import useCart from '../firebase files/hooks/useCart';
import { projectFirestore } from '../firebase config/config';

const OrderSummary = () => {
    const { id } = useParams();
    const { orders } = useOrders();
    const [orderData, setOrderData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {clearCart} = useCart();


    const { document: currencyDocument, error: currencyError } = useDocument(
        "settings",
        "currency"
      );

    const updateProductStock = async (orderData) => {
        try {
            const batch = projectFirestore.batch();
            
            for (const product of orderData.products) {
                const productRef = projectFirestore.collection('products').doc(product.id);
                const productDoc = await productRef.get();
                
                if (productDoc.exists) {
                    const currentStock = productDoc.data().stock;
                    const newStock = currentStock - product.quantity;
                    
                    if (newStock < 0) {
                        throw new Error(`Insufficient stock for ${product.name}`);
                    }
                    
                    batch.update(productRef, { stock: newStock });
                }
            }
            
            await batch.commit();
        } catch (error) {
            console.error('Error updating stock:', error);
            setError('Failed to update product stock');
        }
    };

    useEffect(() => {
        const fetchOrder = async () => {
            if (orders.length === 0) {
                // Orders not loaded yet, keep loading
                return;
            }

            setLoading(true);
            try {
                const order = orders.find(order => order.id === id);
                if (order) {
                    setOrderData(order);
                    await updateProductStock(order); // Update stock after confirming order
                    clearCart();
                    setError(null);
                } else {
                    setError('Order not found');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrder();
    }, [id, orders]);

    console.log("orderData:", orderData);
    console.log("loading:", loading);
    console.log("error:", error);

    if (loading) {
        return <div className="order-summary-page loading">Loading...</div>;
    }

    if (error) {
        return <div className="order-summary-page error">Error: {error}</div>;
    }

    if (!orderData) {
        return <div className="order-summary-page error">No order found with ID: {id}</div>;
    }

    return (
        <div className="order-summary-page">
            <div className="thank-you-message">
                <span className="material-symbols-outlined">
                    check_circle
                </span>
                <h2>Thank You for Ordering!</h2>
                <p><strong>Order ID  : </strong> {id}</p>  
            </div>
            <div className="order-details">
                <div className="order-date">
                    <h4>Order Date:</h4>
                    <p>{orderData.createdAt ? orderData.createdAt.toDate().toLocaleString('IND') : 'N/A'}</p>
                </div>
                <div className="order-total">
                    <h4>Total Order Value :</h4>
                    <p>{currencyDocument.symbol}{orderData.totalPrice}</p>
                </div>
                <div className="order-payment-method">
                    <h4>Payment Method:</h4>
                    <p>{orderData.paymentMethod === "cod" ? "Cash on Delivery" : "Online Payment"}</p>
                </div>
                <div className="order-address">
                    <h4>Shipping Address:</h4>
                    <div className="order-address-type">
                        {
                            orderData.shippingAddress.type === "home" ? <span className="material-symbols-outlined">home</span> : orderData.shippingAddress.type === "work" ? <span className="material-symbols-outlined">work</span> : <span className="material-symbols-outlined">location_on</span>
                        }
                        <span>
                            {orderData.shippingAddress.type.charAt(0).toUpperCase() + orderData.shippingAddress.type.slice(1)}
                        </span>
                    </div>
                    <h5>
                        {orderData.shippingAddress.name}
                    </h5>
                    <p>{orderData.shippingAddress.houseNumber}, {orderData.shippingAddress.streetLineOne}, {orderData.shippingAddress.streetLineTwo}, {orderData.shippingAddress.landmark}, {orderData.shippingAddress.city}, {orderData.shippingAddress.state}, {orderData.shippingAddress.pincode}</p>
                </div>
            </div>
            <div className="order-items">
                <h2>Items</h2>
                <div className="order-items-container">
                {
                    orderData && orderData.products.map((product,index)=>{
                        return (
                            <div className="order-item" key={index}>
                                <img src={product.images[0]} alt="product" />
                                <div className="order-item-details">
                                    <h3>{product.name}</h3>
                                    <p>Price: {currencyDocument.symbol} {product.price}</p>
                                    <p>Quantity: {product.quantity}</p>
                                    <p>Total: {currencyDocument.symbol} {(product.price * product.quantity)}</p>
                                    <p>{product.color ? `Color: ${product.color}` : ""} {product.size ? `Size: ${product.size}` : ""} {product.variantName ? `Variant: ${product.variantName}` : ""}</p>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
            
            <Link to="/orders" className="view-orders-button">View All Orders</Link>
        </div>
    );
};

export default OrderSummary;
